
const images = require.context('images', true)
const imagePath = (name) => images(name, true)

// Stylesheets
import "stylesheets/public.scss"
import "stylesheets/public/slicing/app.css"
import "vue-select/dist/vue-select.css"
import "stylesheets/fonts/_feather.scss"
import "stylesheets/fonts/fontawesome/fontawesome"
import "stylesheets/fonts/fontawesome/regular"
import "stylesheets/fonts/fontawesome/solid"


import RailsUJS from  "@rails/ujs"
RailsUJS.start()
import "bootstrap"
import "../components"

import axios from 'axios'

function setupCSRFToken() {
  const csrfTokenElement = document.querySelector("meta[name=csrf-token]")
  let csrfToken = ''

  if (csrfTokenElement) {
    csrfToken = csrfTokenElement.content
  }
  if (csrfToken) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }
}

window.addEventListener('DOMContentLoaded', setupCSRFToken)
