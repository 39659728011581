<template lang="pug">
  input(type="checkbox" checked="true" @change="onToggle")
</template>

<script>
export default {
  name: 'ToggleAllCheckboxes',
  data: function () {
    return {
      target: this.$attrs['data-target']
    }
  },
  methods: {
    onToggle () {
      const checked = this.$el.checked;

      document.querySelectorAll('input[type="checkbox"].' + this.target).forEach(
          function (checkbox) {
            checkbox.checked = checked;
          }
      );
    }
  },
  mounted() {

  }
}
</script>