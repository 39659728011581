<template lang="pug">
  .stepper-box
    .stepper-progress.d-md-none(v-if='!submitted')
      .card
        .card-body
          .row
            .col-auto.p-2
              .progress.mx-auto(:data-part='Math.round(12*(current_step.index + 1)/(steps.length))')
                span.progress-left
                  span.progress-bar.border-info
                span.progress-right
                  span.progress-bar.border-info
                .progress-value.flex-fill
                  div {{current_step.index + 1}}/{{steps.length}}
            .col.flex-shrink-1.p-2
              h4 {{active_step.title}}
              p.text-muted {{active_step.help_text}}
              .step-icons.d-flex.flex-wrap
                span(:class="['square', isStepActive(index) ? 'active' : 'inactive']", v-for='(step, index) in steps')
                  i.icon.fe.fe-check(v-if='isStepDone(index)')
                  i(:class="['icon', step.icon]", v-if='!isStepDone(index)')
    .d-flex.align-items-center(v-if='loading && !submitted')
      | {{ (contains_payment_step) ? $t('components.stepper.submitting_with_payment') : $t('components.stepper.submitting') }}
      .spinner-grow.ml-auto(role="status" aria-hidden="true")
    .d-flex.align-items-center(v-if='submitted')
      | {{ $t('components.stepper.submitted') }}
      .spinner-grow.ml-auto(role="status" aria-hidden="true")
    .alert.alert-danger.mb-2.mt-1(v-for='api_error in this.api_errors')
      | {{ api_error }}
    .steps-wrapper.p-4(v-if='!loading && !submitted')
      validation-observer(ref='validator', v-slot='{ invalid, errors }')
        template(v-for='(step, index) in steps')
          div(:class="['step', isStepActive(index) ? 'step-active' : 'step-inactive']", :key='index', :ref='step.identifier')
            .step-marker.d-none.d-md-block
              .square
                i.icon.fe.fe-check(v-if='isStepDone(index)')
                i(:class="['icon', step.icon]", v-if='!isStepDone(index)')
              .line
            .step-title
              h3 {{step.title}}
              p.text-muted {{step.help_text}}
            .step-content.clearfix(v-if='isStepDone(index)|| isStepActive(index)')
              template(v-if='step.component != null')
                component(:is='step.component.name', :stepper_data='stepper_data', :extra_data='step.component.data', :backend='backend', @change='handleValueChanged')
              template(v-else='')
                slot(:name='`step_${step.identifier}`', :stepper_data='stepper_data', :handleValueChanged="handleValueChanged", :setIfBlank="setIfBlank", :anyField="anyField", :copyData="copyData", :extra_data='step.extra_data || {}')
              div(:class="[(current_step.index > 0) ? '' : 'only-next']", v-if='isStepActive(index)')
                div(:class="[ backend ? 'btn btn-primary next float-right d-md-none mt-2' : 'cta-button float-left black previous d-md-none mt-2']",  v-if='current_step.index > 0', @click='backStep()')
                  span.arrow-wrapper.left
                    i.icon.fe.fe-arrow-left
                  span.link-label {{ $t('components.stepper.previous') }}
                div(:class="[backend ? 'btn btn-primary next float-right' : 'next cta-button black float-right mt-2', invalid ? 'disabled' : '']", @click='() => nextStep(!invalid)')
                  span.link-label
                    | {{ (final_step) ? $t('components.stepper.finish') : $t('components.stepper.next') }}
                  span.arrow-wrapper
                    i.icon.fe.fe-arrow-right
</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import { BSpinner } from 'bootstrap-vue'
import { serialize } from 'object-to-formdata';
import ClubAndLicenceCategoryStep from "../ClubAndLicenceCategoryStep/ClubAndLicenceCategoryStep";
import LicenceRequestPurchaseConfirmation from "../LicenceRequestPurchaseConfirmation/LicenceRequestPurchaseConfirmation";
import LicenceRequestPhysical from "../LicenceRequestPhysical/LicenceRequestPhysical.vue";
import AccountStep from "../AccountStep/AccountStep";
import PriceInformationStep from '../PriceInformationStep/PriceInformationStep.vue';
import QualityStep from '../QualityStep/QualityStep.vue';

export default {
  filters: {
    translate: function(value, locale) {
      return translations[locale][value];
    }
  },
  props: {
    storage_key: {
      type: String
    },
    steps: {
      type: Array
    },
    initial_form_data: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    backend: {
      type: Boolean,
      required: false,
      default: false
    },
    param_key: {
      type: String,
      required: true
    },
    multipart_form_data: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {

    let payment_step = this.steps.find(function (element) {
      return element.identifier === "payment"
    })

    var initialData = {
      current_step: {},
      substeps: {},
      previous_step: {},
      final_step: false,
      dirty: false,
      loading: false,
      submitted: false,
      api_errors: [],
      contains_payment_step: payment_step !== undefined
    };

    return _.merge({}, initialData, { stepper_data: this.initial_form_data });
  },
  computed: {
    active_step: function() {
      return this.steps.find( (step) => { return (step.identifier == this.current_step.identifier) })
    }
  },
  components: {
    'spinner': BSpinner,
    'club-and-licence-category-step': ClubAndLicenceCategoryStep,
    'licence-request-purchase-confirmation': LicenceRequestPurchaseConfirmation,
    'licence-request-physical': LicenceRequestPhysical,
    'price-information-step': PriceInformationStep,
    'quality-step': QualityStep,
    'account-step': AccountStep,
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  methods: {
    handleSubmit() {
      this.loading = true
      this.api_errors = []

      var data = {
        [this.param_key]: this.stepper_data
      };

      if (this.multipart_form_data) {
        data = serialize(data);
      }

      axios.post(this.url, data, {
        headers: {
          'Content-Type': this.multipart_form_data
            ? 'multipart/form-data'
            : 'application/json'
        },
      }).then((response) =>  {
        this.loading = false
        this.dirty = false
        if (response.data.redirect_url) {
          this.submitted = true
          window.location = response.data.redirect_url
        } else if (response.data.errors) {
          this.api_errors = _.clone(response.data.errors)
        } else {
          this.submitted = true
        }
        sessionStorage.removeItem(this.storage_key)
      }).catch( () => {
      })
    },
    isStepDone(index) {
      return (this.current_step.index > index)
    },
    isStepActive(index) {
      return (this.current_step.index === index)
    },
    activateStep(index, back = false) {
      if (this.steps[index]) {
        var identifier = this.steps[index].identifier
        this.previous_step = this.current_step;
        this.current_step = {
          identifier: identifier,
          index: index,
        };
        if (this.steps[index].submit) {
          this.final_step = true;
        } else {
          this.final_step = false;
        }
      }
      if (this.current_step.identifier && this.$refs[this.current_step.identifier]) {
        this.$refs[this.current_step.identifier][0].scrollIntoView(true)
        this.$nextTick(() => {
          this.$refs[this.current_step.identifier][0].scrollIntoView(true)
        })
      }
    },
    nextStep (valid) {
      if (valid) {
        if (fbq && this.active_step.trigger_fb_pixel_event_on_complete) {
          fbq('track', this.active_step.trigger_fb_pixel_event_on_complete);
        }
        if (this.final_step) {
          this.handleSubmit()
        } else {
          let currentIndex = this.current_step.index + 1;
          this.activateStep(currentIndex);
        }
      } else {
        this.$refs['validator'].validate()
      }
    },
    dataForName(object_name) {
      if (object_name) {
        let path = object_name.replace(/\[/g, '.').replace(/\]/g, '')
        return _.get(this, path)
      } else {
        return {}
      }
    },
    anyField(object_name, field_names) {
      return !_.isEmpty(_.compact(Object.values(_.pick(this.dataForName(object_name), field_names))))
    },
    backStep() {
      let currentIndex = this.current_step.index - 1;
      if (currentIndex >= 0) {
        this.activateStep(currentIndex, true);
      }
    },
    copyData(toKey, fromKey) {

      let fromPath = fromKey.replace(/\[/g, '.').replace(/\]/g, '')
      let toPath = toKey.replace(/\[/g, '.').replace(/\]/g, '')

      _.set(this, toPath, _.clone(_.get(this, fromPath)))
    },
    consoleLog(message) {
      console.log(message)
    },
    unloadHandler() {
      if (this.dirty) {
        return this.$t('components.stepper.beforeunload')
      } else {
        return
      }
    },
    setIfBlank(stepper_data_key, value) {
      let currentValue = _.get(this.stepper_data, stepper_data_key)
      if (!currentValue) {
        _.set(this.stepper_data, stepper_data_key, value);
      }
    },
    handleValueChanged(stepper_data_key, value) {
      _.set(this.stepper_data, stepper_data_key, value);
    }
  },
  watch: {
    stepper_data: {
      handler: function(value) {
        if (this.storage_key) {
          sessionStorage.setItem(this.storage_key, JSON.stringify(value))
          this.dirty = true
        }
      },
      deep: true
    }
  },
  created() {
    if (this.storage_key && sessionStorage.getItem(this.storage_key)) {
      this.stepper_data = JSON.parse(sessionStorage.getItem(this.storage_key))

      // TODO clean reparse dates
      if(this.stepper_data.person_request && this.stepper_data.person_request.birthday) {
        let date = moment(this.stepper_data.person_request.birthday)
        if (date.isValid()) {
          this.stepper_data.person_request.birthday = date.toDate()
        }
      }
    }
    window.onbeforeunload =  this.unloadHandler
    this.activateStep(0);
  }
};
</script>

<style lang="scss">
@import 'stylesheets/components/Stepper/stepper.scss';
</style>
