<template lang="pug" >
  validation-provider(v-if='backend' immediate mode="eager" "v-slot"="{ invalid, errors }" rules="national_number|existing_national_number:false" vid="national_number")
    input(v-mask="'##.##.##-###.##'" :class="['form-control form-control-sm', invalid ? 'is-invalid' : '' ]" :value="national_number" @input="onInput" :id='id' :name='name')
    .invalid-feedback {{ errors[0] }}
  validation-provider(v-else slim immediate mode="eager" "v-slot"="{ invalid, errors, failedRules }" ref="national_number" rules="required|national_number|existing_national_number:true|strict_existing_national_number" vid="national_number")
    .form-row.mt-2
      .float-input__container.active.clearfix.col-md-6
        label(:for='id' class="form_control-label")
          | {{ $t('components.national_number_input.national_number') }}
        input(v-mask="'##.##.##-###.##'" :class="['field sprout-field', invalid ? 'is-invalid' : '' ]" v-model="national_number" :id='id' :name='name' type="text")
        .invalid-feedback {{ errors[0] }}
      .alert.alert-danger.col-md-12(v-if="failedRules.existing_national_number || failedRules.strict_existing_national_number")
        span(v-if="failedRules.existing_national_number" v-html="existingNationalNumberText")
        span(v-if="failedRules.strict_existing_national_number" )
          | {{ $t('components.national_number_input.strict_existing_national_number_text') }}

</template>



<script>

  import _ from 'lodash'
  import { ValidationProvider } from 'vee-validate'

  import { mask } from 'vue-the-mask'

  export default {
    name: 'national-number-input',
    props: {
      'value': {
        type: String,
        required: true
      },
      'name': {
        type: String,
        required: true
      },
      'id': {
        type: String,
        required: true
      },
      'backend': {
        type: Boolean,
        required: false,
        default: false
      },
      'login_url': {
        type: String,
        required: false
      },
      'password_reset_url': {
        type: String,
        required: false
      }
    },
    components: {
      'validation-provider': ValidationProvider,
    },
    directives: {
      'mask' : mask,
    },
    data: function() {
      return {
        national_number: _.clone(this.value),
      }
    },
    computed: {
      existingNationalNumberText() {
        return this.$t('components.national_number_input.existing_national_number_text', {login_link: this.login_url, password_reset_link: this.password_reset_url})
      },
    },
    methods: {
      onInput: function(event) {
        this.national_number = event.target.value
        this.$emit('input', event.target.value)
      }
    },
    watch: {
      'national_number': function(newNationalNumber, oldNationalNumber) {
        this.$emit('change', newNationalNumber)
      }
    }
  }
</script>
