<template lang="pug">
  .card
    .card-header
      h3.card-title
        | {{ $t('components.pre_registration_classifier.pre_registrations') }}
        template('v-if'= 'pre_registration_max')
          |  (Max: {{ pre_registration_max }})
      .card-options
        div.form-check.mr-4
          label.custom-control.custom-checkbox
            input.custom-control-input.form-check-input.boolean(v-model="hide_checked" id="hide_checked" type="checkbox")
            label.custom-control-label.form-check-input.boolean(for="hide_checked")
              | {{ $t('components.pre_registration_classifier.hide_checked') }}
        a.btn.btn-primary('v-if' = 'classification_rule_group' 'v-on:click'='autoClassify'  'href'= '#')
          | {{ $t('components.pre_registration_classifier.auto_classify') }}
        a.btn.btn-primary('v-on:click'='approveAll'  'href'= '#')
          | {{ $t('components.pre_registration_classifier.approve_all') }}

    b-table.mb-0.card-table(hover='true' 'stacked'="md" :items='default_sorted_pre_registrations' :fields='fields' :tbody-tr-class="rowClass")

      template(#cell(startPosition)="data")
        div(:class="data.item.backgroundClass" class="p-3 preregistration-classifier-fill-cell")
          | {{ data.item.startPosition }}

      template(#cell(formatted_date)="data")
          | {{ data.item.formatted_date }}
          input(:value="data.item.id" :name="`admin_pre_registration_batch[pre_registrations_attributes][${data.index}][id]`" type="hidden")

      template(#cell(person_name_with_ids)="data")
        a(:href="data.item.person_path")
          | {{ data.item.person_name_with_ids }}

      template(#cell(person_licence_category_abbreviations)="data")
        div(v-if="classification_required && data.item.person_licence_category_abbreviations")
          | {{ data.item.person_licence_category_abbreviations.join(' | ') }}

      template(#cell(event)="data")
        div.form-group.select(v-if="classification_required")
          select.form-control.form-control-sm.select(:name="`admin_pre_registration_batch[pre_registrations_attributes][${data.index}][event_id]`" v-model="data.item.event_id")
            option(v-for="option in selectable_events_options" v-bind:value="option.value")
              | {{ option.text }}

      template(#cell(status)="data")
        div.form-group.select
          select.form-control.form-control-sm.select(:name="`admin_pre_registration_batch[pre_registrations_attributes][${data.index}][start_status]`" v-model="data.item.start_status")
            option(v-for="option in statuses" v-bind:value="option.value")
              | {{ option.text }}
</template>

<script>

import _ from 'lodash'
import { BTable, BThead, BTr, BTh, BTd, BTbody } from 'bootstrap-vue'

export default {
  name: 'pre-registration-classifier',
  props: {
    'initial_pre_registrations': {
      type: Array,
      required: true
    },
    'pre_registration_start_statuses': {
      type: Array,
      required: true
    },
    'selectable_events': {
      type: Array,
      required: true
    },
    'pre_registration_max': {
      type: Number,
    },
    'classification_rule_group': {
      type: Object
    },
    'classification_required': {
      type: Boolean,
      default: true
    }
  },
  components: {
    'b-table': BTable,
    'b-thead': BThead,
    'b-th': BTh,
    'b-tr': BTr,
    'b-td': BTd,
    'b-tbody': BTbody,
  },
  data: function() {
    return {
      pre_registrations: _.clone(this.initial_pre_registrations),
      hide_checked: false,
      fields: [
          {
              key: 'startPosition',
              label: this.$t('components.pre_registration_classifier.start_position'),
              tdClass: 'position-relative'
          },
          {
              key: 'queuePosition',
              label: this.$t('components.pre_registration_classifier.queue_position'),
          },
          {
              key: 'formatted_date',
              label: this.$t('components.pre_registration_classifier.date'),
              sortable: true,
          },
          {
              key: 'person_name_with_ids',
              label: this.$t('components.pre_registration_classifier.person'),
              sortable: true,
          },
          {
              key: 'person_licence_category_abbreviations',
              label: this.$t('components.pre_registration_classifier.person_licence_categories'),
              sortable: true,
          },
          {
              key: 'person_licence_province_abbreviations',
              label: this.$t('components.pre_registration_classifier.province_short'),
          },
          {
              key: 'person_age',
              label: this.$t('components.pre_registration_classifier.person_age'),
              sortable: true,
          },
          {
              key: 'event',
              label: this.$t('components.pre_registration_classifier.event'),
          },
          {
              key: 'status',
              label: this.$t('components.pre_registration_classifier.start_status'),
          },
      ],
    }
  },
  computed: {
    statuses: function() {
      return this.pre_registration_start_statuses.map(status => ({
        text: this.$t(`components.pre_registration_classifier.start_statuses.${status}`),
        value: status,
      }));
    },
    default_sorted_pre_registrations: function() {
      let sortedPreregistrations = _.sortBy(this.pre_registrations, (pre_registration) => {
        if (pre_registration.start_status =='disapproved') {
          return [2, pre_registration.date ]
        } else {
          return [1, pre_registration.date ]
        }

      })

      return _.map(sortedPreregistrations, (sortedPreregistration, index) => {
        let queuePosition = null
        if (this.pre_registration_max && ((index + 1) > this.pre_registration_max) && (sortedPreregistration.start_status != 'disapproved') ) {
          queuePosition = (index + 1 - this.pre_registration_max)
        }
        let startPosition = index + 1

        if(sortedPreregistration.start_status == 'disapproved') {
          startPosition = null
        }

        let backgroundClass = 'bg-gray'
        if (sortedPreregistration.start_status == 'approved' && !queuePosition) {
          backgroundClass = 'bg-green'
        } else if (sortedPreregistration.start_status == 'approved' && queuePosition) {
          backgroundClass = 'bg-orange'
        } else if (sortedPreregistration.start_status == 'disapproved') {
          backgroundClass = 'bg-red'
        } else if (!this.pre_registration_max) {
          backgroundClass = 'bg-orange'
        } else if (queuePosition) {
          backgroundClass = 'bg-yellow'
        } else {
          backgroundClass = 'bg-orange'
        }

        return _.merge(sortedPreregistration, { backgroundClass: backgroundClass, startPosition: startPosition, queuePosition: queuePosition })
      })
    },
    selectable_events_options: function() {
      return this.selectable_events.map(event => ({
        text: event.classification_name,
        value: event.id,
      }));
    }
  },
  methods: {
    approveAll: function () {
      this.pre_registrations.forEach((pre_registration) => {
        if(pre_registration.start_status != 'disapproved') {
          pre_registration.start_status = 'approved'
        }
      })
    },
    autoClassify: function () {
      this.pre_registrations.forEach((pre_registration) => {
        if (this.classification_rule_group && this.classification_rule_group["rules"] ) {
          let applicableRules = this.classification_rule_group["rules"].filter( (rule) => {
          if (!_.isEmpty(rule.optional_licence_categories) && pre_registration.person_licence_category_ids) {
              let matchingLicenceCategories = rule.optional_licence_categories.filter(value => pre_registration.person_licence_category_ids.includes(value))
              if (!_.isEmpty(matchingLicenceCategories)) {
                return true
              }
            }

            if (rule.min_age && pre_registration.person_age) {
              if (pre_registration.person_age < rule.min_age) {
                return  false
              }
            }

            if (rule.max_age && pre_registration.person_age) {
              if (pre_registration.person_age > rule.max_age) {
                return  false
              }
            }

            if (!_.isEmpty(rule.required_licence_categories) && pre_registration.person_licence_category_ids) {
              let matchingLicenceCategories = rule.required_licence_categories.filter(value => pre_registration.person_licence_category_ids.includes(value))
              if (_.isEmpty(matchingLicenceCategories)) {
                return false
              }
            }

            return true
          })

          if(!_.isEmpty(applicableRules)) {
            let appliedRule = applicableRules[0]
            pre_registration.event_id = appliedRule.round_event_id
          }
        }
      })
    },
    rowClass(item, type) {
      if (item.start_status != 'pending' && this.hide_checked) {
        return "hidden";
      }
    }
  },
}
</script>
