import { extend } from 'vee-validate';
import { required, email, confirmed, min_value, length } from "vee-validate/dist/rules";
import IBAN from "iban"
import axios from 'axios'

import moment from 'moment'

extend('iban', (value) => {
    return IBAN.isValid(value); // true
})

extend('required', required)
extend('email', email)
extend('confirmed', confirmed)
extend('min_value', min_value)
extend('length', length)

extend('must_be_true', value => {
  return value != null && value;
});

extend('any_checked', {
  validate(value) {
    return value != null && value;
  },
  message: 'Minstens 1 vinkje aanduiden'
})

extend('competition_or_organizational_required', {
  params: ['competition', 'organizational'],
  validate(value, { competition, organizational }) {
    return (competition || organizational)
  },
  message: 'Minstens competitief of organisatorisch moet opgegeven zijn'
})

extend('national_number', (value)  => {
    value = value.replace(/\D/g,'');
    // RR numbers need to be 11 chars long
    if (value.length != 11) {
      return false;
    }

    // DO NOT check birthday because some people get a national number without knowing their birthday
    // in which case they sometimes get 000000 as birthdayDigits
    // var birthdayDigits = value.substr(0, 5)
    //
    // var birthday = moment(birthdayDigits, 'YYMMDD')
    // if (!birthday.isValid()) {
    //   return false
    // }


    var checkDigit = value.substr(value.length - 2, 2);

    var modFunction = function(number) {
      return 97 - (number % 97);
    };

    var numberToCheck = parseInt(value.substr(0, 9));

    // first check without 2
    if (modFunction(numberToCheck) != checkDigit) {
      numberToCheck = parseInt('2' + value.substr(0, 9));
      if (modFunction(numberToCheck) != checkDigit) {
        return false;
      }
    }

    return true
})

extend('existing_national_number', {
  validate(value, { public_request }) {
    if (value) {
      value = value.replace(/\D/g,'');
      return axios.get('/national_numbers.json', { params: {search: value, strict: false } }).then(function(response) {
        if(response.data && response.data.emails) {
          if (public_request === 'true') {
            return `Dit rijksregisternummer wordt al beheerd door ${response.data.emails}.`
          } else {
            return `Dit rijksregisternummer wordt al beheerd door ${response.data.emails}. Bij het indienen wordt dit emailadres op de hoogte gebracht.`
          }
        } else {
          return true
        }
      }).catch(function(error) {
        return 'Deze functionaliteit is even niet beschikbaar, probeer later opnieuw'
      })
    } else {
      return 'Gelieve een rijksregisternummer op te geven'
    }
  },
  params: ['public_request']
})

extend('strict_existing_national_number', {
  validate(value, args ) {
    if (value) {
      value = value.replace(/\D/g,'');
      return axios.get('/national_numbers.json', { params: {search: value, strict: true } }).then(function(response) {
        console.log("we did a lookup")
        console.log(response)
        if(response.data && response.data.person) {
          return 'Een persoon met dit rijksregisternummer is reeds gekend in het systeem'
        } else {
          return true
        }
      }).catch(function(error) {
        return 'Deze functionaliteit is even niet beschikbaar, probeer later opnieuw'
      })
    } else {
      return 'Gelieve een rijksregisternummer op te geven'
    }
  }
})

extend('flanders_postal_code', {
  validate(value, args) {
    if (value) {
      var postal_code = parseInt(value)
      var flanders = _.range(1000,1300).concat(_.range(1500,4000)).concat(_.range(8000,10000))

      if (flanders.includes(postal_code)) {
        return true
      }

      return 'Postcode ligt niet in Vlaanderen'
    }
  }
})

extend('password_complexity', {
  validate(value, args) {
    if (value.length < 8) {
      return 'moet minstens 8 karakters lang zijn'
    }

    var matches = /(?=.*?[0-9])/.test(value)
    if (!matches) {
      return 'moet minstens 1 cijfer bevatten'
    }

    return true
  }
})
