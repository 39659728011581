<template lang="pug">
  .card
    .card-header
      h3.card-title
        | Document
      .card-options
        button.btn.btn-secondary.mr-4(@click="previousPage" :disabled="(page <= 0)")
          i.fe.fe-chevron-left
        span.align-middle.mr-2.mt-2 Pagina
        input(@input="setPage" :value="page" style="width: 5em")
        span.align-middle.ml-2.mt-2 /{{numPages}}
        button.btn.btn-secondary.ml-4(@click="nextPage" :disabled="(page >= numPages)")
          i.fe.fe-chevron-right
      .card-options
        button.btn.btn-secondary(@click="$refs.pdfComponent.print()")
          i.fe.fe-printer
    .card-body
      div(v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }")
        | {{ Math.floor(loadedRatio * 100) }}
      pdf(ref="pdfComponent" :src="src" :page="page" @progress="loadedRatio = $event" @error="error" @num-pages="numPages = $event" @link-clicked="page = $event")
</template>
<script>
import pdf from 'vue-pdf'

export default {
	components: {
		'pdf': pdf
	},
	data () {
		return {
			loadedRatio: 0,
			page: 1,
			numPages: 0,
			rotate: 0,
		}
	},
  props: {
    src: {
      type: String,
      required: true
    }
  },
	methods: {
    setPage: function(event) {
      let newPage = parseInt(event.target.value)
      if ( (newPage <= this.numPages) && (newPage > 0)) {
        this.page = newPage
      }
    },
    nextPage: function() {
      if (this.page <= this.numPages) {
        this.page = this.page + 1
      }
    },
    previousPage: function() {
      if (this.page > 1) {
        this.page = this.page - 1
      }
    },
		error: function(err) {
			console.log(err);
		}
	}
}
</script>
