<template lang="pug">
  div
    .form-row.mt-4
      label(v-for="sex in sexes")
        input.mx-2(type="radio" name="sex" :value="sex" v-model="selected_sex")
        | {{ sex }}
    .form-row.mt-4
      span.fields.mt-2.select.required.foreign_rider_request_licence_category_name.col-md-12
        .float-input__container.active.clearfix(v-bind:class="selected_sex ? '' : 'opacity-50'")
          label.form-control-label.select.required(for="foreign_rider_request_licence_category_name")
            | Your licence category in your home federation
            abbr(title="required") *
          select.field.sprout-field.select.required(name="foreign_rider_request[licence_category_name]" id="foreign_rider_request_licence_category_name" :disabled="!selected_sex")
            option(value="")
            option(v-for="category in filteredCategories")
              | {{ category }}
</template>

<script>
export default {
  name: 'ForeignRiderRequestLicenceCategory',
  data: function () {
    return {
      selected_sex: '',
      sexes: ['male', 'female'],
      categories: ["ELITE MEN", "ELITE WOMEN", "U23 MEN", "U23 WOMEN", "JUNIOR MEN", "JUNIOR WOMEN", "YOUTH MEN - U17", "YOUTH WOMEN - U17", "YOUTH MEN - U15", "YOUTH WOMEN - U15", "YOUTH MEN - U12", "YOUTH WOMEN - U12", "MASTERS MEN 35+", "MASTERS WOMEN 35+", "AMATEUR MEN", "AMATEUR WOMEN"],
    };
  },
  computed: {
    filteredCategories: function () {
      if (!this.selected_sex) {
        return this.categories;
      }

      var selected_sex = this.selected_sex;

      return this.categories.filter(function (category) {
        return selected_sex == 'male'
          ? category.match(/(^|\s)MEN/)
          : category.match(/(^|\s)WOMEN/);
      });
    },
  },
}
</script>
