<template lang="pug">
  div(:class="param_class")
    select(:class="['w-100', frontend ? '' :  'form-control form-control-sm']" :value='selected_option' @input="onInput" :filterable="false" :placeholder="$t('components.remote_select.placeholder')")
      template(v-if="!options.length")
        option(:value='""') {{ $t('components.remote_select.no_matching_options') }}
      template(v-else)
        option(:value='""')
      template(v-for="option in options")
        option(:value='option.value' :selected='option.value === selected_value') {{option.label}}
    input(:name="name" :id="id" type='hidden' :value="selected_value")
</template>

<script>

import VueSelect from 'vue-select'

import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'remote-select',
  components: {
    'vue-select': VueSelect,
  },
  props: {
    'url': String,
    'initial_options': {
      type: Array
    },
    'frontend': {
      type: Boolean,
      default: false
    },
    'value': {
      type: String,
    },
    'name': {
      type: String,
      required: true
    },
    'id': {
      type: String,
      required: true
    },
    'filter_params': {
      type: Object,
      required: true
    },
    'param_class': {
      type: String,
      required: false
    }},
  data: function() {
    return {
      options: _.clone(this.initial_options || []),
      selected_value: _.clone(this.value),
    }
  },
  computed: {
    selected_option: function() {
      if (!this.selected_value) {
        return ''
      }

      let option = this.options.find( (option) => {
        return (option.value == this.selected_value)
      })

      return option
    }
  },
  created() {
    if (this.url) {
      this.fetchOptions()
    }
  },
  watch: {
    filter_params: {
      deep: true,
      handler: function(oldValue, newValue) {
        if(!_.isEqual(oldValue,newValue) && this.url) {
          this.fetchOptions()
        }
      }
    }
  },
  methods: {
    fetchOptions: function() {
      axios.get(this.url, { params:  _.merge({}, this.filter_params || {})}).then((response) => {
        this.options = response.data
        if (!this.selected_option) {
          this.selected_value = null
        }
      })
    },
    onInput: function(event) {
      this.selected_value = event.target.value
      this.$emit('input', this.selected_value)
    },
  }
}
</script>
