<template>
  <div :class="[frontend ? 'grid__block--buttons ml-0' : 'selectgroup']">
    <span :class="[frontend ? '' : 'selectgroup-item',(option.value == select_value) ? 'active' : '']" v-for="option in options">
      <span class="selectgroup-button" @click="() => onSelect(option.value)" v-if="!frontend">
        {{ option.label }}
      </span>
      <span :class="['select-button',(option.value == select_value) ? 'active' : '' ]" @click="() => onSelect(option.value)" v-if="frontend">
        <span class="link-label">
          {{ option.label }}
        </span>
      </span>
    </span>
    <input :name="name" :id="id" type='hidden' :value="select_value"/>
  </div>
</template>

<script>

import _ from 'lodash'

export default {
  name: 'button-select',
  props: {
    'options': {
      type: Array
    },
    'frontend': {
      type: Boolean,
      default: false
    },
    'value': {
      type: String,
      required: true
    },
    'name': {
      type: String,
      required: true
    },
    'id': {
      type: String,
      required: true
    }},
  data: function() {
    return {
      select_value: _.clone(this.value),
    }
  },
  methods: {
    onSelect: function(value) {
      this.select_value = value
      this.$emit('input', value)
    },
  }
}
</script>
