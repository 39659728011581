<template lang="pug">
  .row
    .col-12
      .alert.alert-danger.mb-2.mt-1(v-for='error in this.errors')
        | {{ error }}
      .card
        .card-body
          .row
            .col-lg-3
              .input-group
                input.form-control#default_coda_search(v-model="coda_search.name", placeholder="Naam", type="text")
                .input-group-append
                  .dropdown.dropright
                    button.btn.btn-secondary.dropdown-toggle(type="button", data-toggle="dropdown") {{ $t('components.coda_payments_reconciliator.search')}}
                    .dropdown-menu.dropdown-menu-wide.p-4.w-50
                      .row
                        .col-md-6
                          .form-group
                            label(for="search_coda_bank_account") {{ $t('components.coda_payments_reconciliator.bank_account_nr')}}
                            input.form-control#search_coda_bank_account(v-model="coda_search.account")
                        .col-md-6
                          .form-group
                            label(for="search_coda_name") {{ $t('components.coda_payments_reconciliator.name')}}
                            input.form-control#search_coda_name(v-model="coda_search.name")
                      .row
                        .col-md-6
                          .form-group
                            label(for="search_coda_amount") {{ $t('components.coda_payments_reconciliator.amount')}}
                            input.form-control#search_coda_amount(v-model="coda_search.amount")
                        .col-md-6
                          .form-group
                            label(for="search_coda_communication") {{ $t('components.coda_payments_reconciliator.communication')}}
                            input.form-control#search_coda_communication(v-model="coda_search.communication")

                      .row
                        .col-md-6
                          label(for="search_booking_date_from") {{ $t('components.coda_payments_reconciliator.booking_date_from')}}
                          date-time-picker#search_booking_date_from(v-model="coda_search.booking_date_from", name="search_booking_date_from", ':date_only'="true", value="false")
                        .col-md-6
                          label(for="search_booking_date_till") {{ $t('components.coda_payments_reconciliator.booking_date_till')}}
                          date-time-picker#search_booking_date_till(v-model="coda_search.search_booking_date_till", name="search_booking_date_till", ':date_only'="true", value="false")
            .col-lg-3
            .col-lg-3
            .col-lg-3
              .input-group
                .input-group-prepend
                  .dropdown.dropleft
                    button.btn.btn-secondary.dropdown-toggle(type="button", data-toggle="dropdown") {{ $t('components.coda_payments_reconciliator.search')}}
                    .dropdown-menu.dropdown-menu-wide.p-4.w-50(@click='keep_menu_open')
                      .row
                        .col-md-6
                          .form-group
                            label(for="search_payment_applicant") {{ $t('components.coda_payments_reconciliator.applicant')}}
                            input.form-control#search_payment_applicant(v-model="payment_search.applicant")
                        .col-md-6
                          .form-group
                            label(for="search_payment_bank_account_nr") {{ $t('components.coda_payments_reconciliator.bank_account_nr')}}
                            input.form-control#search_payment_bank_account_nr(v-model="payment_search.bank_account_nr")
                      .row
                        .col-md-6
                          .form-group
                            label(for="search_payment_amount_greater_than") {{ $t('components.coda_payments_reconciliator.amount_greater_than')}}
                            input.form-control#search_payment_amount_greater_than(v-model="payment_search.amount_greater_than")
                        .col-md-6
                          .form-group
                            label(for="search_payment_amount_smaller_than") {{ $t('components.coda_payments_reconciliator.amount_smaller_than')}}
                            input.form-control#search_payment_amount_smaller_than(v-model="payment_search.amount_smaller_than")
                        .col-md-6
                          .form-group
                            label(for="search_payment_communication") {{ $t('components.coda_payments_reconciliator.communication')}}
                            input.form-control#search_payment_communication(v-model="payment_search.communication")
                        .col-md-6
                          .form-group
                            label(for="search_payment_payout_reference") {{ $t('components.coda_payments_reconciliator.payout_reference')}}
                            input.form-control#search_payment_payout_reference(v-model="payment_search.payout_reference")
                      .row
                        .col-md-6
                          .form-group
                            label(for="search_payment_licence_category") {{ $t('components.coda_payments_reconciliator.licence_category')}}
                            select.form-control.select#search_payment_applicant(v-model="payment_search.licence_category")
                              option(value="")
                                | ---
                              option(v-for="option in licence_categories" v-bind:value="option.id")
                                | {{ option.name }}
                        .col-md-6
                          .form-group
                            label(for="search_payment_club") {{ $t('components.coda_payments_reconciliator.club')}}
                            input.form-control#search_payment_club(v-model="payment_search.club")
                      .row
                        .col-md-6
                          label
                            | Club type
                          .form-group
                            label.custom-checkbox--container.d-block
                              input.custom-checkbox(type="checkbox", v-model="payment_search.recreational")
                              span Recreatief
                            label.custom-checkbox--container.d-block
                              input.custom-checkbox(type="checkbox", v-model="payment_search.competition")
                              span Competitief
                            label.custom-checkbox--container.d-block
                              input.custom-checkbox(type="checkbox", v-model="payment_search.organizational")
                              span Organiserend
                input.form-control#default_payment_search(v-model="payment_search.all", placeholder="", type="text")
          .row
            .col-lg-3
              .row
                .col-lg-12
                  | {{ $t('components.coda_payments_reconciliator.searched_coda_lines')}}
              .row
                .col-lg-12
                  ul.list-group.vh-30.overflow-auto
                    template(v-for='(coda_line, index) in searched_coda_lines')
                      li.list-group-item.list-group-item-small.clearfix.hover-toggle-collapse(:key="index")
                        button.btn.btn-light.float-right(@click='useSelectedCodaLine(index)')
                          i.fe.fe-chevrons-right
                        b {{ coda_line.account + " - " + coda_line.formatted_amount }}
                        br
                        small {{ coda_line.communication ? coda_line.communication : $t('components.coda_payments_reconciliator.no_ogm') }}
                        br
                        small {{ coda_line.name ? coda_line.name : $t('components.coda_payments_reconciliator.no_name') }}
                        .collapse.border-top.pt-1
                          small {{ coda_line.address ? coda_line.address : $t('components.coda_payments_reconciliator.no_address') }}
                          br
                          small {{ coda_line.entry_date }}

            .col-lg-3
              .row
                .col-lg-12
                  | {{ $t('components.coda_payments_reconciliator.used_coda_lines')}}
              .row
                .col-lg-12
                  ul.list-group.vh-30.overflow-auto
                    template(v-for='(coda_line, index) in used_coda_lines')
                      li.list-group-item.list-group-item-small.clearfix.hover-toggle-collapse(:key="index")
                        button.btn.btn-light.float-left.mr-2(@click='unUseSelectedCodaLine(index)')
                          i.fe.fe-chevrons-left
                        template(v-if="coda_line.formatted_unreconciled_amount == coda_line.formatted_amount")
                          b {{ coda_line.account }} - {{ coda_line.formatted_amount }}
                        template(v-else)
                          b {{ coda_line.account }} - ({{coda_line.formatted_unreconciled_amount }}/{{coda_line.formatted_amount }})
                        br
                        small {{ coda_line.communication ? coda_line.communication : $t('components.coda_payments_reconciliator.no_ogm') }}
                        br
                        small {{ coda_line.name ? coda_line.name : $t('components.coda_payments_reconciliator.no_name') }}
                        .collapse.border-top.pt-1
                          small {{ coda_line.address ? coda_line.address : $t('components.coda_payments_reconciliator.no_address') }}
                          br
                          small {{ coda_line.entry_date }}

            .col-lg-3
              .row
                .col-lg-12
                  | {{ $t('components.coda_payments_reconciliator.used_payments')}}
              .row
                .col-lg-12
                  ul.list-group.vh-30.overflow-auto
                    template(v-for='(payment, index) in used_payments')
                      li.list-group-item.list-group-item-small.clearfix(:key="index")
                        button.btn.btn-light.float-right(@click='unUseSelectedPayment(index)')
                          i.fe.fe-chevrons-right
                        b {{ `${(payment.applicant_bank_account_nr) ? payment.applicant_bank_account_nr : $t('components.coda_payments_reconciliator.no_bank_account_nr')} -` }}

                        template(v-if="payment.formatted_unreconciled_amount == payment.formatted_amount")
                          b {{ payment.account }} - {{ payment.formatted_amount }}
                        template(v-else)
                          b {{ payment.account }} - ({{payment.formatted_unreconciled_amount }}/{{payment.formatted_amount }})
                        br
                        small {{ payment.ogm ? payment.ogm : $t('components.coda_payments_reconciliator.no_ogm') }}
                        br
                        small {{ payment.formatted_applicant ? payment.formatted_applicant : $t('components.coda_payments_reconciliator.no_name') }}

            .col-lg-3
              .row
                .col-lg-12
                  | {{ $t('components.coda_payments_reconciliator.searched_payments')}}
              .row
                .col-lg-12
                  ul.list-group.vh-30.overflow-auto
                    template(v-for='(payment, index) in searched_payments')
                      li.list-group-item.list-group-item-small.clearfix(:key="index")
                        button.btn.btn-light.float-left.mr-2(@click='useSelectedPayment(index)')
                          i.fe.fe-chevrons-left
                        b {{ `${(payment.applicant_bank_account_nr) ? payment.applicant_bank_account_nr : $t('components.coda_payments_reconciliator.no_bank_account_nr')}` }}

                        template(v-if="payment.formatted_unreconciled_amount == payment.formatted_amount")
                          b {{ payment.account }} - {{ payment.formatted_amount }}
                        template(v-else)
                          b {{ payment.account }} - ({{payment.formatted_unreconciled_amount }}/{{payment.formatted_amount }})
                        br
                        small {{ payment.ogm ? payment.ogm : $t('components.coda_payments_reconciliator.no_ogm') }}
                        br
                        small {{ payment.formatted_applicant ? payment.formatted_applicant : $t('components.coda_payments_reconciliator.no_name') }}
        .card-body
          .template(v-for='(coda_line, index) in used_coda_lines')
            .row
              .col-lg-5
                .border.border-dark.w-100.pt-1.pb-1.pr-4.pl-4.mb-1.clearfix(:class="codaLineReconciled(coda_line.id) ? 'bg-green' : ''")
                  .dropdown.float-right
                    button.btn.btn-light.dropdown-toggle(type="button", data-toggle="dropdown")
                      | {{ $t('components.coda_payments_reconciliator.correction')}}
                    .dropdown-menu
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "surplus")') {{ $t('components.coda_payments_reconciliator.surplus')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "repaid")') {{ $t('components.coda_payments_reconciliator.repaid')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "payment_difference")') {{ $t('components.coda_payments_reconciliator.payment_difference')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "banking_cost")') {{ $t('components.coda_payments_reconciliator.banking_cost')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "transfer")') {{ $t('components.coda_payments_reconciliator.transfer')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "waiting_account")') {{ $t('components.coda_payments_reconciliator.waiting_account')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "deposit")') {{ $t('components.coda_payments_reconciliator.deposit')}}
                      label.dropdown-item(@click='addCorrectionForCodaLine(coda_line.id, "other")') {{ $t('components.coda_payments_reconciliator.other')}}
                  | {{ `${coda_line.account} - ${coda_line.formatted_amount}` }}
                  br
                  | {{ `${coda_line.communication || $t('components.coda_payments_reconciliator.no_communication')} - ${coda_line.name ? coda_line.name : $t('components.coda_payments_reconciliator.no_name') }` }}
              .col-lg-7
                .template(v-for='(reconciliation, index) in reconciliationsForCodaLine(coda_line.id)')
                  .row
                    .col-lg-3
                        input.w-100(type="number", v-model="reconciliation.amount")
                    .col-lg-9
                      template(v-if="reconciliation.payment_id")
                        .border.border-dark.w-100.pt-1.pb-1.pr-4.pl-4.mb-1.clearfix(:class="paymentReconciled(reconciliation.payment_id) ? 'bg-green' : ''")
                          | {{ `${used_payments[reconciliation.payment_id].applicant_bank_account_nr ? used_payments[reconciliation.payment_id].applicant_bank_account_nr : $t('components.coda_payments_reconciliator.no_bank_account_nr')}
                          | - ${used_payments[reconciliation.payment_id].formatted_amount}` }}
                          br
                          | {{ `${used_payments[reconciliation.payment_id].ogm ? used_payments[reconciliation.payment_id].ogm : $t('components.coda_payments_reconciliator.no_ogm')}
                          | - ${used_payments[reconciliation.payment_id].formatted_applicant ? used_payments[reconciliation.payment_id].formatted_applicant : $t('components.coda_payments_reconciliator.no_name')}` }}
                      template(v-else-if="reconciliation.correction_id && getCorrection(reconciliation.correction_id)")
                        .border.border-dark.w-100.pt-1.pb-1.pr-4.pl-4.mb-1.clearfix
                          button.btn.btn-light.float-right.mr-2(@click='removeCorrection(reconciliation.correction_id)')
                            i.fe.fe-trash-2
                          | {{ `${$t('components.coda_payments_reconciliator.correction')}: ${$t('correction.correction_type.' + getCorrection(reconciliation.correction_id).correction_type)}
                          | - €${reconciliation.amount}` }}
            hr
          .row(v-if="reconciliations.length")
            .col-lg-5.text-right
              h3 {{ `${$t('components.coda_payments_reconciliator.total_coda')}: €${coda_lines_total}` }}
            .col-lg-2.text-center
              h3 {{ `${$t('components.coda_payments_reconciliator.reconciled')}: €${total_reconciled}` }}
              button.btn.btn-light.w-100(:class="canBeSubmitted() ? '' : 'disabled'", @click="handleSubmit()", v-if="!submitting && !redirecting")
                | {{ allCodaLinesReconciled() && allPaymentsReconciled() ?  $t('components.coda_payments_reconciliator.submit') : $t('components.coda_payments_reconciliator.partially_submit')}}
              .d-flex.justify-content-center(v-if="submitting || redirecting")
                | {{ $t('components.coda_payments_reconciliator.processing')}}
                |
                b-spinner(label="Spinning")
            .col-lg-5.text-left
              h4 {{ `${$t('components.coda_payments_reconciliator.total_payments')}: €${payments_total + corrections_total} (€${payments_total}|€${corrections_total} )` }}


</template>

<script>

  import axios from 'axios'
  import {BSpinner} from 'bootstrap-vue'
  import _ from 'lodash'
  import DateTimePicker from "../DateTimePicker/DateTimePicker";

  export default {
    name: 'CodaPaymentsReconciliator',
    props: {
      selected_coda_line: {
        type: Object,
        required: true
      },
      selected_payments: {
        type: Array,
        required: true
      },
      url: {
        required: true
      },
      coda_search_url: {
        required: true
      },
      payment_search_url: {
        required: true
      },
      licence_categories: {
        type: Array,
        required: true
      },
    },
    data: function() {
      let used_coda_lines = {}
      used_coda_lines[this.selected_coda_line.id] = this.selected_coda_line

      let searched_payments = _.keyBy(this.payments, 'id')

      let used_payments = _.keyBy(this.selected_payments, 'id')
      for (const key of Object.keys(used_payments)) {
        delete searched_payments[key]
      }

      return {
        searched_coda_lines: {},
        used_coda_lines: used_coda_lines,
        searched_payments: searched_payments,
        used_payments: used_payments,
        reconciliations: [],
        corrections: [],
        errors: [],
        submitting: false,
        redirecting: false,
        coda_search: {account: "", name: "", booking_date_from: null, booking_date_till: null, amount: null, communication: null, status: "pending"},
        payment_search: {communication: null, applicant: null, bank_account_nr: null, amount: null, status: "pending", payment_type: "TransferPayment", licence_category: null, club: null, recreational: null, competition: null, organizational: null, all: null}
      };
    },
    components: {
      BSpinner,
      'date-time-picker': DateTimePicker
    },
    computed: {
      coda_lines_total: function() {
        let self = this
        let total = 0
        let processed_coda_line_ids = []

        this.reconciliations.forEach(function(reconcilication) {
          if(reconcilication.coda_line_id && !processed_coda_line_ids.includes(reconcilication.coda_line_id)) {
            total += parseFloat(self.used_coda_lines[reconcilication.coda_line_id].amount_euro)
            processed_coda_line_ids.push(reconcilication.coda_line_id)
          }
          else if(!reconcilication.coda_line_id) {
            total += parseFloat(reconcilication.amount) || 0
          }
        })

        return total
      },
      corrections_total: function() {
        let self = this
        let total = 0

        this.reconciliations.forEach(function(reconcilication) {
          if(!reconcilication.payment_id) {
            total += parseFloat(reconcilication.amount) || 0
          }
        })

        return total
      },
      payments_total: function() {
        let self = this
        let total = 0
        let processed_payment_ids = []

        this.reconciliations.forEach(function(reconcilication) {
          if(reconcilication.payment_id && !processed_payment_ids.includes(reconcilication.payment_id)) {
            total += parseFloat(self.used_payments[reconcilication.payment_id].amount_euro)
            processed_payment_ids.push(reconcilication.payment_id)
          }
        })

        return total
      },
      total_reconciled: function() {
        return this.reconciliations.map(reconciliation => parseFloat(reconciliation.amount) || 0).reduce(function(accumulator, currentValue) {
          return accumulator + currentValue
        }, 0)
      }
    },
    methods:{
      useSelectedCodaLine(index) {
        let selected_coda_line = _.get(this.searched_coda_lines, index)
        if (selected_coda_line) {
          this.$delete(this.searched_coda_lines, index)
          this.$set(this.used_coda_lines, index, selected_coda_line)
        }
        this.buildReconciliations()
      },
      unUseSelectedCodaLine(index) {
        let selected_coda_line = _.get(this.used_coda_lines, index)
        if (selected_coda_line) {
          this.$delete(this.used_coda_lines, index)
          this.$set(this.searched_coda_lines, index, selected_coda_line)
          _.remove(this.corrections, function(correction) {
            return correction.coda_line_id === index
          })
        }
        this.buildReconciliations()
      },
      useSelectedPayment(index) {
        let payment = _.get(this.searched_payments, index)
        if (payment) {
          this.$delete(this.searched_payments, index)
          this.$set(this.used_payments, index, payment)
        }
        this.buildReconciliations()
      },
      unUseSelectedPayment(index) {
        let payment = _.get(this.used_payments, index)
        if (payment) {
          this.$delete(this.used_payments, index)
          this.$set(this.searched_payments, index, payment)
          _.remove(this.corrections, function(correction) {
            return correction.payment_id === index
          })
        }
        this.buildReconciliations()
      },
      buildReconciliations() {
        let new_reconciliations = []
        let self = this
        for (const [coda_key, coda_value] of Object.entries(this.used_coda_lines)) {
          for (const [pay_key, pay_value] of Object.entries(this.used_payments)) {
            let found = this.reconciliations.find(function(element) {
              return element.coda_line_id === coda_key && element.payment_id === pay_key;
            });

            if (found) {
              new_reconciliations.push(found)
            }
            else {
              new_reconciliations.push({
                id: 'new_' + Math.random().toString(36).substr(2, 9),
                coda_line_id: coda_key,
                payment_id: pay_key,
                correction_id: null,
                amount: "0"
              })
            }
          }

          let coda_corrections = this.corrections.filter(function(correction) {
            return correction.coda_line_id === coda_key
          })

          if(coda_corrections.length) {
            coda_corrections.forEach(function(correction) {
              let coda_correction_found = self.reconciliations.find(function(reconciliation) {
                return reconciliation.coda_line_id === coda_key && reconciliation.correction_id === correction.id;
              });

              if(coda_correction_found) {
                new_reconciliations.push(coda_correction_found)
              }
              else {
                new_reconciliations.push({
                  id: 'new_' + Math.random().toString(36).substr(2, 9),
                  coda_line_id: coda_key,
                  payment_id: null,
                  correction_id: correction.id,
                  amount: "0"
                })
              }
            })
          }
        }

        let payment_corrections = this.corrections.filter(function(correction) {
          return correction.payment_id != null
        })

        if(payment_corrections.length) {
          payment_corrections.forEach(function(correction) {
            let payment_reconciliation_found = self.reconciliations.find(function(reconciliation) {
              return reconciliation.payment_id === correction.payment_id && reconciliation.correction_id === correction.id;
            });

            if(payment_reconciliation_found) {
              new_reconciliations.push(payment_reconciliation_found)
            }
            else {
              new_reconciliations.push({
                id: 'new_' + Math.random().toString(36).substr(2, 9),
                coda_line_id: null,
                payment_id: correction.payment_id,
                correction_id: correction.id,
                amount: "0"
              })
            }
          })
        }
        this.reconciliations = new_reconciliations
      },
      getCorrection(correction_id) {
        return this.corrections.find(function (correction) {
          return correction.id === correction_id;
        })
      },
      paymentReconciled(payment_id) {
        let payment = this.used_payments[payment_id]
        return this.reconciliations.filter( (reconciliation) => {
          return reconciliation.payment_id === payment.id
        }).reduce((accum,item) => accum + parseFloat(item.amount), 0) === parseFloat(payment.amount_euro)
      },
      codaLineReconciled(coda_line_id) {
        let coda_line = this.used_coda_lines[coda_line_id]
        return this.reconciliationsForCodaLine(coda_line_id).reduce((accum,item) => accum + parseFloat(item.amount), 0) === parseFloat(coda_line.unreconciled_amount_euro)
      },
      reconciliationsForCodaLine(coda_line_id) {
        return this.reconciliations.filter( (reconciliation) => {
          return reconciliation.coda_line_id === coda_line_id
        })
      },
      addCorrectionForCodaLine(coda_line_id, correction_type) {
        let correction = {
          id: 'new_' + Math.random().toString(36).substr(2, 9),
          correction_type: correction_type,
          coda_line_id: coda_line_id,
          payment_id: null
        }

        this.corrections.push(correction)
        this.buildReconciliations()
      },
      removeCorrection(correction_id) {
        _.remove(this.corrections, function(correction) {
          return correction.id === correction_id
        })
        this.buildReconciliations()
      },
      canBeSubmitted() {
        return this.allCodaLinesReconciled();
      },
      allPaymentsReconciled() {
        let payments_ok = true
        for (const payment of Object.values(this.used_payments)) {
          payments_ok = payments_ok && this.paymentReconciled(payment.id)
        }

        return payments_ok
      },
      allCodaLinesReconciled() {
        let coda_lines_ok = true
        for (const coda_line of Object.values(this.used_coda_lines)) {
          coda_lines_ok = coda_lines_ok && this.codaLineReconciled(coda_line.id)
        }

        return coda_lines_ok
      },
      handleSubmit() {
        if(!this.canBeSubmitted()) return

        this.submitting = true

        let reconciliations = _.clone(this.reconciliations)
        _.remove(reconciliations, function(reconciliation) {
          return parseFloat(reconciliation.amount) === 0
        })

        let corrections = _.clone(this.corrections)
        _.remove(corrections, function(correction) {
          let matching_reconciliation = reconciliations.find(function (reconciliation) {
            return reconciliation.correction_id === correction.id;
          })
          return matching_reconciliation == null
        })

        axios.put(this.url,
          {
            reconciliations: reconciliations,
            corrections: corrections
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) =>  {
          // this.loading = false
          if (response.data.redirect_url) {
            this.redirecting = true
            this.submitting = false
            this.errors = []
            window.location = response.data.redirect_url
          } else if (response.data.errors) {
            this.submitting = false
            this.errors = _.clone(response.data.errors)
          } else {
            this.submitting = false
            this.errors = []
            // this.submitted = true
          }
        }).catch( () => {
        })
      },
      search_codas() {
        axios.get(this.coda_search_url,
          {
            params: this.coda_search,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }).then((response) =>  {
            let searched_coda_lines = _.keyBy(response.data, 'id')
            for (const [coda_key, coda_value] of Object.entries(this.used_coda_lines)) {
              delete searched_coda_lines[coda_key]
            }
            this.searched_coda_lines = searched_coda_lines
        }).catch( () => {
        })
      },
      search_payments() {
        axios.get(this.payment_search_url,
          {
            params: this.payment_search,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }).then((response) =>  {
          let searched_payments = _.keyBy(response.data, 'id')
          for (const [payment_key, payment_value] of Object.entries(this.used_payments)) {
            delete searched_payments[payment_key]
          }
          this.searched_payments = searched_payments
        }).catch( () => {
        })
      },
      keep_menu_open(event) {
        event.stopPropagation();
      }
    },
    watch: {
      coda_search: {
        deep: true,
        handler: _.debounce(function() { this.search_codas() }, 300)
      },
      payment_search: {
        deep: true,
        handler: _.debounce(function() { this.search_payments() }, 300)
      },

    },
    mounted: function () {
      this.search_codas()
      this.search_payments()
      this.buildReconciliations()
    }
  }
</script>
