<template>
  <div class="card">
    <div class="card-body">
      <div class="form-row" v-if="quality_labels.length">
        <div class="form-group col-md-3">
          <label for="quality_label">Kwaliteitslabels</label>
          <select
            class="form-control"
            v-model="selected_quality_label"
            id="quality_label"
          >
            <option value=""></option>
            <option v-for="label in quality_labels" :value="label">
              {{ label.name}}
            </option>
          </select>
        </div>
      </div>
      <div>
        <div
          class="mb-4"
          v-for="(quality_items, category) in grouped_quality_items"
        >
          <strong class="d-block mb-2">{{ category }}</strong>
          <div
            v-for="item in quality_items"
          >
            <div
              class="px-2 mb-1"
              :class='highlight_item(item) ? "bg-yellow-light" : ""'
            >
              <div class="form-check">
                <label class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input form-check-input boolean optional"
                    type="checkbox"
                    :value="item.id"
                    v-model="stepper_data.quality_items"
                    :disabled="disable_item(item)"
                  >
                  <span class="custom-control-label form-check-input boolean optional">
                    {{ item.name }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: "QualityStep",
  props: {
    stepper_data: {
      type: Object,
      required: true
    },
    extra_data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      quality_items: this.extra_data.quality_items,
      quality_labels: this.extra_data.quality_labels,
      tour_types: this.extra_data.tour_types,
      selected_quality_label: null,
    }
  },
  computed: {
    selected_tour_type: function () {
      var selected_tour_type_id = this.stepper_data.tour_type_id;

      return this.tour_types.find(function (item) {
        return item.id == selected_tour_type_id;
      });
    },
    required_quality_items: function () {
      if (!this.selected_tour_type || !this.selected_tour_type.quality_label) {
        return [];
      }

      return this.selected_tour_type.quality_label.quality_items || [];
    },
    grouped_quality_items: function () {
      return _.groupBy(this.quality_items, function (item) {
        return item.category;
      });
    },
  },
  methods: {
    check_required_quality_items: function () {
      const required_quality_item_ids = this.required_quality_items
        .map(function (item) {
          return item.id;
        });

      this.stepper_data.quality_items = _.uniq(
        this.stepper_data.quality_items.concat(required_quality_item_ids)
      );
    },
    disable_item: function (item) {
      return this.required_quality_items.some(function (required_item) {
        return item.id == required_item.id;
      });
    },
    highlight_item: function (item) {
      if (!this.selected_quality_label) {
        return false;
      }

      return this.selected_quality_label.quality_items.some(function (quality_item) {
        return quality_item.id == item.id;
      });
    },
  },
  watch: {
    selected_tour_type: function (new_selected_tour_type) {
      this.check_required_quality_items();
    },
  },
  created: function () {
    this.check_required_quality_items();
  },
}
</script>
