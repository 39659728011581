<template lang="pug">
  a.btn.btn-secondary( href="javascript:void(0)" @click="onToggle" )
    template(v-if="visible") {{ hide }}
    template(v-else="!visible") {{ show }}
</template>

<script>
export default {
  name: 'ToggleHideableRows',
  data: function () {
    return {
      visible: false,
      show: this.$attrs['data-show'],
      hide: this.$attrs['data-hide']
    }
  },
  methods: {
    onToggle () {
      const visible = this.visible;

      this.$el.closest('.card').querySelector('.table-responsive').querySelectorAll('tr.hideable').forEach(
          function (row) {
            if (visible) {
              row.classList.add('hidden')
            }
            else {
              row.classList.remove('hidden')
            }
          }
      )

      this.visible = !visible;
      this.$el.blur();
    }
  },
  mounted() {

  }
}
</script>