<template lang="pug">
  .form
    .row.vdivide
      .col-md-6
        h4 {{ $t('components.event.basic_info') }}
        .form-row
          .form-group.col-md-6
            label.form-control-label.required {{ $t('activemodel.attributes.event.start_date') }}
            date-time-picker(:name="`${name}[start_date]`" :id="`${name}_start_date`" v-model='event_data.start_date' :date_only="true" :invalid="errors['start_date']")
            .invalid-feedback(v-if="errors['start_date']") {{ errors['start_date'][0] }}
          .form-group.col-md-5
            label.form-control-label.required {{ $t('activemodel.attributes.event.remarks') }}
            textarea.form-control(:name="`${name}[remarks]`" v-model="event_data.remarks")
        h4(v-if='show_preregistration') {{ $t('components.event.pre_registration') }}
        .form-row(v-if='show_preregistration')
          .form-group.col-md-6
            label.custom-control.custom-checkbox
              input(:name="`${name}[pre_registration_allowed]`" type='hidden' value="false")
              input.custom-control-input(:name="`${name}[pre_registration_allowed]`" type='checkbox' value="true" v-model="event_data.pre_registration_allowed")
              .custom-control-label {{ $t('activemodel.attributes.event.pre_registration') }}
          .form-group.col-md-6(v-if="event_data.pre_registration_allowed && show_preregistrations_until")
            label.form-control-label.required {{ $t('activemodel.attributes.event.pre_registrations_until') }}
            date-time-picker(:name="`${name}[pre_registrations_until]`" :id="`${name}_pre_registrations_until`" v-model='event_data.pre_registrations_until')
        .form-row(v-if="event_data.pre_registration_allowed && show_preregistration_max")
          .form-group.col-md-6
            label.form-control-label {{ $t('activemodel.attributes.event.pre_registration_max') }}
            input.form-control.form-control-sm(:name="`${name}[pre_registration_max]`" type='text' v-model="event_data.pre_registration_max")
        h4 {{ $t('components.event.registration') }}
        .form-row
          .form-group.col-md-6
            label.form-control-label.required {{ $t('activemodel.attributes.event.registrations_from') }}
            date-time-picker(:name="`${name}[registrations_from]`" :id="`${name}_registrations_from`" v-model='event_data.registrations_from' :time_only="true")
          .form-group.col-md-6
            label.form-control-label.required {{ $t('activemodel.attributes.event.registrations_until') }}
            date-time-picker(:name="`${name}[registrations_until]`" :id="`${name}_registrations_until`" v-model='event_data.registrations_until' :time_only="true")
        h4 {{ $t('components.event.commencement') }}
        .form-row
          .form-group.col-md-6
            label.custom-switch(v-for="type in ['not_applicable', 'fixed', 'variable']")
              input.custom-switch-input(:name="`${name}[commencement_type]`" type='radio' :value="type" v-model="event_data.commencement_type")
              .custom-switch-indicator
              .custom-switch-description {{ $t(`event.commencement_type.${type}`) }}
          .form-group.col-md-6(v-if="event_data.commencement_type == 'fixed'")
            label.form-control-label.required {{ $t('activemodel.attributes.event.commencement_time') }}
            date-time-picker(:name="`${name}[commencement_time]`" :id="`${name}_commencement_time`" v-model='event_data.commencement_time' :time_only="true")
      .col-md-6
        h4 {{ $t('components.event.calendar') }}
        .form-row
          .form-group.col-md-8
            label.form-control-label.required {{ $t('activemodel.attributes.event.name') }}
            input.form-control.form-control-sm(:name="`${name}[name]`" type='text' v-model="event_data.name")
        .form-row.mt-2
          .form-group.col-md-12
            label.custom-switch
              input.custom-switch-input(:name="`${name}[full_day]`" type='radio' value="true" v-model="event_data.full_day")
              .custom-switch-indicator
              .custom-switch-description {{ $t('components.event.full_day_active') }}
            label.custom-switch
              input.custom-switch-input(:name="`${name}[full_day]`" type='radio' value="false" v-model="event_data.full_day")
              .custom-switch-indicator
              .custom-switch-description {{ $t('components.event.full_day_not_active') }}
        .form-row
          .form-group.col-md-6(v-if='event_data.full_day != "true"')
            label.form-control-label.required {{ $t('activemodel.attributes.event.start_time') }}
            date-time-picker(:name="start_time_name" :id="start_time_id" v-model="event_data.start_time" :invalid="errors['start_time']" :time_only="true")
            .invalid-feedback(v-if="errors['start_time']") {{ errors['start_time'][0] }}
          input(type= 'hidden' :name="start_time_name" v-else :value="start_time")
          .form-group.col-md-6(v-if='event_data.full_day != "true"')
            label.form-control-label.required {{ $t('activemodel.attributes.event.end_time') }}

            date-time-picker(:name="end_time_name" :id="end_time_id" v-model="event_data.end_time" :invalid="errors['end_time']" :time_only="true")
            .invalid-feedback(v-if="errors['end_time']") {{ errors['end_time'][0] }}
          input(type= 'hidden' :name="end_time_name" v-else :value="end_time")
        .form-row
          .form-group.col-md-6
            label.form-control-label.required {{ $t('activemodel.attributes.event.province') }}
            select.form-control.form-control-sm(:name="province_name" v-model="event_data.province")
              option('v-for'= "province in provinces", ':value'= 'province') {{  province ? $t(`provinces.${province}`) : $t(provinces.ongekend) }}
        .form-row
          .form-group.col-md-6
            label.form-control-label.required {{ $t('activemodel.attributes.event.status') }}
            select.form-control.form-control-sm(:name="status_name" v-model="event_data.status")
              option('v-for'= "status in statuses", ':value'= 'status') {{  status ? $t(`event.status.${status}`) : '' }}
</template>

<script>

import DateTimePicker from '../DateTimePicker'

import moment from 'moment'

export default {
  name: 'Event',
  components: {
    'date-time-picker': DateTimePicker,
  },
  props: {
    'event': {
      type: Object,
    },
    'event_type': {
      type: String,
    },
    'errors': {
      type: Object,
    },
    'name': {
      type: String,
      required: true
    }
  },
  computed: {
    start_time_name: function () {
      return `${this.name}[start_time]`
    },
    start_time_id: function () {
      return `${this.name}_start_time`
    },
    end_time_name: function () {
      return `${this.name}[end_time]`
    },
    end_time_id: function () {
      return `${this.name}_end_time`
    },
    province_name: function () {
      return `${this.name}[province]`
    },
    status_name: function () {
      return `${this.name}[status]`
    },
    show_preregistration: function() {
      return ['race','rider_exam', 'tour'].includes(this.event_type)
    },
    show_preregistration_max: function() {
      return ['race','tour', 'rider_exam'].includes(this.event_type)
    },
    show_preregistrations_until: function() {
      return ['race', 'tour','rider_exam'].includes(this.event_type)
    },
    statuses: function() {
      return ['active', 'cancelled', 'deleted']
    },
    provinces: function() {
      return [ "brussel", "waals_brabant", "vlaams_brabant", "antwerpen", "limburg", "luik", "namen", "henegouwen", "luxemburg", "west_vlaanderen", "oost_vlaanderen", null]
    }
  },
  data: function () {
    return {
      event_data: _.clone(this.event),
    }
  },
}
</script>
