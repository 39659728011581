<template>
    <div class="row">
        <div class="col-md-12" v-if="this.errors['main_error'] != null">
          <div class="alert alert-danger mb-2 mt-1">
            {{ this.errors['main_error'] }}
          </div>
        </div>
        <div class="col-md-12">
            <template v-if="!loading && !submitted">
                <div class="card">
                    <div class="card-header">
                        {{ $t('components.club_licence_request_batch_renewal.title') }}
                    </div>
                    <template v-if="!licence_requests_found">
                        <div class="alert alert-info">
                            {{ $t('components.club_licence_request_batch_renewal.no_licence_requests_found')}}
                        </div>
                    </template>
                    <template v-else>
                        <template v-for="(licence_request, index) in this.licence_requests_data.licence_requests_attributes">
                            <div :ref="(has_error(licence_request) ? 'redborder': 'noborder')" :class="'card-body' + (has_error(licence_request) ? ' red-border': '')">
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <fieldset class="form-group boolean optional search_active">
                                            <div class="form-check">
                                                <label class="custom-control custom-checkbox">
                                                    <input :id="'renew_' + index" type="checkbox" v-model="licence_request.selected" class="custom-control-input form-check-input boolean optional">
                                                    <label :for="'renew_' + index" class="custom-control-label form-check-input boolean optional">
                                                        {{get_person_name(licence_request)}}
                                                    </label>
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="form-group row select required">
                                            <div class="col-sm-4">
                                                {{ $t('components.club_licence_request_batch_renewal.licence_category')}}:
                                            </div>
                                            <div class="col-sm-8">
                                                {{ get_licence_category_name(licence_request.licence_category_id) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="has_error(licence_request)">
                                    <div class="row">
                                        <div class="col-md-12 text-red">
                                            {{get_error(licence_request)}}
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </div>
                <template v-if="licence_requests_found">
                    <input type="button" value="Vergunningsaanvraag indienen" data-disable-with="Vergunningsaanvraag indienen" class="btn btn-primary" @click="handleSubmit">
                </template>
            </template>
            <template v-if="loading && !submitted">
                <b-spinner small label="Spinning"/> Uw aanvragen worden ingediend
            </template>
            <template v-if="submitted">
                Vergunningsaanvragen werden ingediend
            </template>
        </div>
    </div>
</template>

<script>

  import {BSpinner} from 'bootstrap-vue'
  import axios from 'axios'
  import _ from 'lodash'

  export default {
    props: {
      initial_form_data: {
        type: Object,
        required: true
      },
      licence_categories: {
        type: Array,
        required: true
      },
      people: {
        type: Object,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      param_key: {
        type: String,
        required: true
      }
    },
    data: function () {
      let data = _.cloneDeep(this.initial_form_data)
      if (data.licence_requests_attributes != null) {
        data.licence_requests_attributes.forEach(function (item, index) {
          item.selected = false
        })
      }

      let initialData = {
        licence_requests_data: data,
        errors: [],
        loading: false,
        submitted: false,
        allow_scrolling: false,
        licence_requests_found: (data.licence_requests_attributes != null)
      };

      return initialData
    },
    components: {
        BSpinner,
    },
    updated() {
      this.$nextTick(function () {
        this.scroll()
      })
    },
    methods: {
      get_person_name(licence_request) {
        let person = _.get(this.people, licence_request.person_id)
        return person.last_name + ' ' + person.first_name
      },
      get_licence_category_name(licence_category_id) {
        let licence_category = this.licence_categories.find(
          lc => lc.id === licence_category_id
        )
        return licence_category.name;
      },
      has_error(licence_request) {
        return licence_request.selected && this.errors[licence_request.person_id + '_' + licence_request.licence_category_id] != null
      },
      get_error(licence_request) {
        if (!licence_request.selected) return null

        let messages = this.errors[licence_request.person_id + '_' + licence_request.licence_category_id]

        if (messages != null) return messages[0]
        else return null
      },
      handleSubmit() {
        if (!this.licence_requests_found) {
          return
        }

        this.loading = true
        let renewed_licences = {licence_requests: []}
        this.licence_requests_data.licence_requests_attributes.forEach(function (item, index) {
          if (item.selected) {
            renewed_licences.licence_requests.push(item)
          }
        })
        axios.post(this.url,
          {
            [this.param_key]: renewed_licences
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
              this.loading = false
            if (response.data.errors) {
              this.allow_scrolling = true
              this.errors = response.data.errors
            }
            else if (response.data.redirect_url) {
              window.location = response.data.redirect_url
            }
            else {
              this.errors = {}
              this.submitted = true
            }
            // sessionStorage.removeItem(this.storage_key)
          }).catch(() => {
        })
      },
      scroll() {
        if (!this.allow_scrolling) return

        let el = null
        let red_borders = this.$refs.redborder
        if (Array.isArray(red_borders) && red_borders.length) {
          el = red_borders[0]
        }

        if (el) {
          el.scrollIntoView({
              block: 'center'
          })
          this.allow_scrolling = false
        }
      }
    }
  };
</script>
