<template>
    <div class="w-100">
        <label class="form-label">{{ $t('components.uci_selector.uci_data') }}</label>
        <div class="d-flex justify-content-center" v-if="request_data_and_spin">
            <b-spinner style="width: 3rem; height: 3rem;" label="Spinning"></b-spinner>
        </div>
        <div class="row vdivide" v-else>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.first_name')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.first_name}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.last_name')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.last_name}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.birthday')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.birthday}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.gender')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.gender}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6">
                        <label>
                            {{$t('components.uci_selector.national_number')}}
                        </label>
                    </div>
                    <div class="col-lg-6 text-right">
                        <label>
                            {{request_data.national_number}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.country')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.country}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.roles')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.roles}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.year')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.year}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>
                            {{$t('components.uci_selector.email')}}
                        </label>
                    </div>
                    <div class="col-lg-8 text-right">
                        <label>
                            {{request_data.email}}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="show_in_modal || (uci_id == null)">
            <label class="form-label">{{ $t('components.uci_selector.possible_ids') }}</label>
            <div class="d-flex justify-content-center" v-if="request_and_spin">
                <b-spinner style="width: 3rem; height: 3rem;" label="Spinning"></b-spinner>
            </div>
            <div class="form-group" v-else>
                <div class="selectgroup selectgroup-vertical w-100 uci-scrollable" v-if="possible_uci_ids.length">
                    <label class="selectgroup-item" v-for="possible_id in possible_uci_ids">
                        <input type="radio" name="id_selection" :value="possible_id.uciid" :checked="possible_id.match"
                               class="selectgroup-input">
                        <span class="selectgroup-button" @click="personClicked(possible_id.uciid)">{{ possible_id.first_name + " " + possible_id.last_name + " - " + possible_id.birthdate + " - " + possible_id.uciid }}</span>
                    </label>
                </div>
                <div v-else>
                    <b-button variant="outline-dark" @click="requestUCIId">
                        {{ requestButtonText }}
                    </b-button>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label">{{ $t('components.uci_selector.new_uci_id') }}</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Enter UCI ID" disabled :value="changed_uci_id">
                    <template v-if="!requestNewUciIdAllowed">
                <span class="input-group-append">
                    <b-button variant="outline-dark"
                              disabled>{{ $t('components.uci_selector.request_new_uci_id') }}</b-button>
                </span>
                    </template>
                    <template v-if="!request_new_and_spin && requestNewUciIdAllowed">
                <span class="input-group-append">
                    <b-button variant="outline-dark" @click="requestNewUCIId">{{ $t('components.uci_selector.request_new_uci_id') }}</b-button>
                </span>
                    </template>
                    <template v-if="request_new_and_spin">
                        <b-button variant="outline-dark">
                            <b-spinner small label="Spinning"/>
                            {{ $t('components.uci_selector.request_new_id_loading') }}
                        </b-button>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import {BButton, BModal, BSpinner, VBModal} from 'bootstrap-vue'

export default {
    name: 'UCISelectorContent',
    props: {
        'uci_id': {
            type: String
        },
        'changed_uci_id': {
            type: String
        },
        'request_data_and_spin': {
            type: Boolean,
            required: true
        },
        'request_and_spin': {
            type: Boolean,
            required: true
        },
        'request_new_and_spin': {
            type: Boolean,
            required: true
        },
        'possible_uci_ids': {
            type: Array,
            required: true
        },
        'force_token': {
            type: String
        },
        'request_data': {
            type: Object,
            required: true
        },
        'show_in_modal': {
            type: Boolean,
            required: true
        }
    },
    components: {
        'modal': BModal,
        BSpinner,
        BButton
    },
    directives: {
        'modal': VBModal
    },
    computed: {
        requestButtonText: function () {
            if (this.request_data_and_spin) {
                return I18n.t('components.uci_selector.wait_for_data')
            }
            else if (this.request_data.error == null) {
                return I18n.t('components.uci_selector.request_uci_id')
            }
            else {
                return I18n.t('components.uci_selector.fetch_ids_error', {error: this.request_data.error})
            }
        },
        requestNewUciIdAllowed: function () {
            return this.force_token != null
        }
    },
    methods:{
        personClicked(value) {
            this.$emit('personClicked', value);
        },
        requestUCIId() {
            this.$emit('requestUCIId');
        },
        requestNewUCIId() {
            this.$emit('requestNewUCIId');
        }
    }
}
</script>
