<template lang="pug">

  .card
    .card-body
      .form-row
        label.custom-checkbox--container.d-block
          input.custom-checkbox(type="checkbox", name="generate_physical", v-model="generate_physical", :checked="generate_physical" @change="updateGeneratePhysical")
          template(v-if="searching_price")
            span
              | {{$t('components.licence_generation.physical').replaceAll('%{price}', '???')}}
              spinner(:small="true", label="Spinning")
          template(v-else)
            span
              | {{$t('components.licence_generation.physical').replaceAll('%{price}', this.price)}}
          span.custom-checkbox--box

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import axios from 'axios'
import { BSpinner } from 'bootstrap-vue'
import _ from "lodash";

export default {
  name: "LicenceRequestPhysical",
  props: {
    stepper_data: {
      type: Object,
      required: true
    },
    extra_data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      price: "",
      price_found: false,
      searching_price: true,
      initialized: false,
      price_url: this.extra_data.price_url,
      price_category: this.extra_data.price_category,
      generate_physical: false
    }
  },
  components: {
    'validation-provider': ValidationProvider,
    'spinner': BSpinner
  },
  methods: {
    updateGeneratePhysical(value) {
      this.stepper_data.generate_physical = this.generate_physical
      this.$emit('change', 'generate_physical', this.generate_physical)
    },
    search_price: function () {
      this.searching_price = true
      this.price_found = false
      let self = this
      axios.get(this.extra_data.price_url,
          {
            params: this.build_params()
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
        self.price = response.data.price
        self.price_found = !response.data.error;
      }).catch(function (error) {
        console.log(error)
        self.price_found = false
      }).finally(function () {
        self.searching_price = false
      });
    },
    build_params: function () {
      return {
        period: this.stepper_data.period,
        price_category: this.price_category
      }
    }
  },
  watch: {
    'stepper_data.period': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    }
  },
  mounted: function () {
    this.search_price();
    this.initialized = true
  }
}
</script>
