<template lang="pug">
  .card
    .card-body
      .form-row
        label {{ $t(this.help_text_key) }}
      .form-row.mt-2
        .col-md-6
          validation-provider.fields.string.required(rules='required|email', v-slot='{ errors }', vid="email")
            .float-input__container.active.clearfix
              label.form-control-label.string.required(for='email') {{$t('components.account_step.email')}}
              input.field.sprout-field.string.required#email(type="email" name='email', v-model='email', size=50, :class="errors[0] ? 'w-auto is-invalid' : 'w-auto'")
              .invalid-feedback {{ errors[0] }}
      .form-row.mt-2
        .col-md-6
          validation-provider.fields.string.required(rules='required|confirmed:email', v-slot='{ errors }')
            .float-input__container.active.clearfix
              label.form-control-label.string.required(for='email_confirmation') {{$t('components.account_step.email_confirmation')}}
              input.field.sprout-field.string.required#email_confirmation(type="email" name='email_confirmation', v-model='email_confirmation', size=50, :class="errors[0] ? 'w-auto is-invalid' : 'w-auto'", onpaste="return false;")
              .invalid-feedback {{ errors[0] }}
      template(v-if='searching_for_account')
        // Hidden input has been put here so that validations can never pass when checking account
        validation-provider.hidden(rules='required', v-slot='{ errors }')
          input#hidden_input(v-model="hidden_input", name="hidden_input", type="text")
        b-spinner.small(label="Spinning", v-if="!account_search_error")
        label(v-if="account_search_error") {{$t('components.account_step.account_search_error')}}
      template(v-if="request_password && password_required && !searching_for_account")
        .form-row.mt-2
          .col-md-6
            validation-provider.fields.string.required(rules='required|password_complexity', v-slot='{ errors }', vid="password")
              .float-input__container.active.clearfix
                label.form-control-label.string.required(for='password') {{$t('components.account_step.password')}}
                input.field.sprout-field.string.required#password(type="password" name='password', v-model='password', size=50, :class="errors[0] ? 'w-auto is-invalid' : 'w-auto'", :hint="$t('public.messages.password_complexity')")
                .invalid-feedback {{ errors[0] }}
        .form-row.mt-2
          .col-md-6
            validation-provider.fields.string.required(rules='required|confirmed:password', v-slot='{ errors }')
              .float-input__container.active.clearfix
                label.form-control-label.string.required(for='password_confirmation') {{$t('components.account_step.password_confirmation')}}
                input.field.sprout-field.string.required#password_confirmation(type="password" name='password_confirmation', v-model='password_confirmation', size=50, :class="errors[0] ? 'w-auto is-invalid' : 'w-auto'", onpaste="return false;")
                .invalid-feedback {{ errors[0] }}
</template>

<script>
import {BSpinner} from 'bootstrap-vue'
import {ValidationProvider} from 'vee-validate'
import axios from "axios";
import _ from "lodash";
import Qs from "qs";

// Format nested params correctly for Rails
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {

    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});

export default {
  name: "AccountStep",
  props: {
    stepper_data: {
      type: Object,
      required: true
    },
    extra_data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      help_text_key: _.clone(this.extra_data.help_text_key),
      email: _.clone(this.stepper_data.account_attributes.email) || "",
      email_confirmation: _.clone(this.stepper_data.account_attributes.email_confirmation),
      password: _.clone(this.stepper_data.account_attributes.password),
      password_confirmation: _.clone(this.stepper_data.account_attributes.password_confirmation),
      request_password: _.clone(this.extra_data.request_password),
      password_required: false,
      searching_for_account: false,
      hidden_input: null,
      account_search_path: _.clone(this.extra_data.account_search_path),
      account_search_error: false
    }
  },
  computed: {
  },
  components: {
    'validation-provider': ValidationProvider,
    BSpinner,
  },
  methods: {
    findAccount: _.debounce(function () {
      if (this.email.length && this.request_password) {
        this.searching_for_account = true

        let params = {
          "email": this.email,
        }

        let self = this

        axios.get(this.extra_data.account_search_path, {params: params}).then(function (response) {
          self.password_required = !response.data.password_present
          if (!self.password_required) {
            self.password = null
            self.password_confirmation = null
          }
          self.account_search_error = false
          self.searching_for_account = false
        }).catch(function (error) {
          self.account_search_error = true
        }).finally(function () {
        });
      }
    }, 300)
  },
  watch: {
    email: function (newEmail, oldEmail) {
      this.$emit('change', 'account_attributes.email', newEmail)

      this.findAccount()
    },
    email_confirmation: function (newEmailConfirmation, oldEmailConfirmation) {
      this.$emit('change', 'account_attributes.email_confirmation', newEmailConfirmation)
    },
    password: function (newPassword, oldPassword) {
      this.$emit('change', 'account_attributes.password', newPassword)
    },
    password_confirmation: function (newPasswordConfirmation, oldPasswordConfirmation) {
      this.$emit('change', 'account_attributes.password_confirmation', newPasswordConfirmation)
    },
    password_required: function (newPasswordRequired, oldPasswordRequired) {
      this.$emit('change', 'account_attributes.password_required', newPasswordRequired)
    }
  },
  mounted() {
    this.findAccount()
  }
}
</script>
