<template>
  <div>
    <vue-bootstrap4-table :rows="rows" :default_selected_columns="default_selected_columns" :column_definitions="column_definitions" :classes="classes" :show_loader="show_loader" @on-change-query="onChangeQuery" :total-rows="total_rows"  :csv_url="csv_url" :full_csv_url="full_csv_url" :xlsx_url="xlsx_url" :full_xlsx_url="full_xlsx_url" :extra_actions_with_search ="extra_actions_with_search" :eager_load="eager_load">
     <template slot="empty-results">
        Geen resultaten
      </template>
     <template slot="reset-button-text">
        <i class='fe fe-trash'></i>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    </vue-bootstrap4-table>
  </div>
</template>

<script>

import VueBootstrap4Table from './VueBootstrap4Table.vue'
import axios from 'axios'
import Qs from 'qs' // Query String
import _ from 'lodash'

// Format nested params correctly for Rails
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {

    return Qs.stringify(params, {
      arrayFormat: "brackets"
    });
  };

  return config;
});

export default {
  name: 'DataTable',
  props: {
    'url': String,
    'extra_actions': {
      type: Array,
      default: []
    },
    'column_definitions': {
      type: Object,
      required: true
    },
    'default_selected_columns': {
      type: Array,
      required: true
    },
    eager_load: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    extra_actions_with_search: function() {
      return this.extra_actions.map((extra_action) => {
        let string_params = Qs.stringify(this.params, {
          arrayFormat: "brackets",
          encode: false
        })
        return _.merge({}, extra_action, {search_url: `${extra_action['url']}?${string_params}`});
      })

    },
    csv_url: function() {
      let string_params = Qs.stringify(this.params, {
        arrayFormat: "brackets",
        encode: false
      })

      let current_params = this.url.split('?')[1]

      if (current_params) {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${current_params}&${string_params}`
      } else {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${string_params}`
      }
    },
    full_csv_url: function() {
      let string_params = Qs.stringify(_.merge({} ,this.params, { unpaged: true }), {
        arrayFormat: "brackets",
        encode: false
      })

      let current_params = this.url.split('?')[1]

      if (current_params) {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${current_params}&${string_params}`
      } else {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${string_params}`
      }
    },
    xlsx_url: function() {
      let string_params = Qs.stringify(this.params, {
        arrayFormat: "brackets",
        encode: false
      })

      let current_params = this.url.split('?')[1]

      if (current_params) {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${current_params}&${string_params}`
      } else {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${string_params}`
      }
    },
    full_xlsx_url: function() {
      let string_params = Qs.stringify(_.merge({} ,this.params, { unpaged: true }), {
        arrayFormat: "brackets",
        encode: false
      })

      let current_params = this.url.split('?')[1]

      if (current_params) {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${current_params}&${string_params}`
      } else {
        return `${this.url.split('?')[0].replace(/.json$/, '.xlsx')}?${string_params}`
      }
    },
    params: function() {
      let result = { "page": this.queryParams.page, "per_page": this.queryParams.per_page }

      let sortParams = []

      this.queryParams.sort.forEach( (sortQuery) => {
        sortParams.push({ field: sortQuery.name, direction: sortQuery.order })
      })

      result["sort"] = sortParams

      this.queryParams.filters

      let filters = {}

      Object.keys(this.queryParams.filters).forEach( (filterKey) => {
        let value = this.queryParams.filters[filterKey]
        if (value) {
          filters[filterKey] = value
        }
      })

      return _.merge({} , result, filters)
    }
  },
  data: function() {
    return {
      rows: [],
      axiosCancelSources: [],
      total_rows: 0,
      show_loader: false,
      classes: {
        table: 'table-hover table-outline text-nowrap card-table'
      },
      queryParams: {
        sort: [],
        filters: {},
        per_page: 25,
        page: 1,
      },
    }
  },
  mounted() {
    this.$root.$on('refresh-data-table', () => {   this.fetchData() })
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    log(message) {
      console.log(message);
    },
    fetchData() {
      let self = this;

      this.show_loader= true;

      this.axiosCancelSources.forEach((source) => {
        source.cancel();
      });
      this.axiosCancelSources = [];

      const cancelSource = axios.CancelToken.source();
      this.axiosCancelSources.push(cancelSource);

      axios.get(this.url, {
        params: this.params,
        cancelToken: cancelSource.token,
      }).then(function(response) {
        self.show_loader = false
        self.rows = response.data;
        self.total_rows = response.headers && response.headers['x-total-count'];
      })
      .catch(function(error) {
        console.log(error);
      });
    }

  },
  components: {
    VueBootstrap4Table
  }
}
</script>

<style lang="scss">
  @import 'stylesheets/components/DataTable/bootstrap_overrides.scss';
</style>
