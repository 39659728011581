<template>
  <modal :id='id' size='lg' :title="$t('components.columns_modal.title')" hide-backdrop ok-only static>
    <div class='alert alert-info'>
      {{ $t('components.columns_modal.info') }}
    </div>
    <div class='row row-fluid'>
      <div class="col-md-6">
        <h4>{{ $t('components.columns_modal.selected_columns') }}</h4>
        <draggable class="list-group" tag="ul" v-model="selected_columns" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false" @change="onChange">
          <transition-group type="transition" :name="'flip-list'">
            <li class="list-group-item" v-for="column in selected_columns" :key="column.key">
              <i :class="column.fixed? 'fe fe-move' : 'fe fe-menu'" @click=" column.fixed=! column.fixed" aria-hidden="true"></i>
              {{column_definitions[column.key].label}}
            </li>
          </transition-group>
        </draggable>
      </div>

      <div class="col-md-6">
        <h4>{{ $t('components.columns_modal.unselected_columns') }}</h4>
        <draggable tag="span" v-model="unselected_columns" v-bind="dragOptions" :move="onMove">
          <transition-group name="no" class="list-group" tag="ul">
            <li class="list-group-item" v-for="column in unselected_columns" :key="column.key">
              <i :class="column.fixed? 'fe fe-move' : 'fe fe-menu'" @click=" column.fixed=! column.fixed" aria-hidden="true"></i>
              {{column_definitions[column.key].label}}
            </li>
          </transition-group>
        </draggable>
      </div>
    </div>
  </modal>
</template>

<script>

import { BModal, VBModal } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import hash from 'object-hash'

export default {
  name: 'ColumnsModal',
  props: {
    'id': String,
    'column_definitions': {
      type: Object,
      required: true
    },
    'default_selected_columns': Array,
  },
  data: function() {
    return {
      selected_columns: [],
      unselected_columns: [],
      editable: true,
      isDragging: false,
      delayedDragging: false
    }
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onChange() {
      this.$emit('change',this.selected_columns.map( (column) => { return column.key }))
    }
  },
  mounted() {

    if (localStorage.getItem(this.storage_key)) {
      this.selected_columns = JSON.parse(localStorage.getItem(this.storage_key))
    } else {
      this.selected_columns = _.cloneDeep(this.default_selected_columns).map((column_key) => {
        return { key: column_key }
      })
    }


    this.unselected_columns = _.difference(_.cloneDeep(Object.keys(this.column_definitions)), _.cloneDeep(this.selected_columns.map((column) => column.key))).map((column_key) => {
      return { key: column_key }
    })
    this.onChange()
  },
  computed: {
    storage_key() {
      return `cycling_portal_selected_columns_v2_${hash(this.column_definitions)}`
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
  },
  components: {
    'modal': BModal,
    Draggable,
  },
  directives: {
    'modal': VBModal
  },
  watch: {
    selected_columns: {
      handler: function(value) {
        localStorage.setItem(this.storage_key, JSON.stringify(value))
        this.dirty = true
      },
      deep: true
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style lang="scss">
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 50px;
    border: 2px solid gray;
  }
  .list-group-item {
    cursor: move;
  }
  .list-group-item i {
    cursor: pointer;
  }
</style>
