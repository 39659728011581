
<template>
  <div>
    <div class="card">
      <div class="card-header">
      <h2 class='card-title'>{{ $t('components.search_form.title') }}</h2>
      </div>
      <div class="card-body">
        <div class="input-group mb-3" v-if="global_search">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="collapse" data-target='#advanced-search' aria-haspopup="true" aria-expanded="false">
              <i class="fe fe-search"></i>
            </button>
          </div>
          <input type="text" class="form-control" v-model="search[default_search]">
        </div>
        <div :class="[ global_search ? 'collapse' : '']" id='advanced-search'>
          <div class='px-4 -py3'> <slot :search="search"></slot>
          </div>
        </div>
        <div>
          <span v-for="search_key in Object.keys(search)" v-if="search[search_key]" class="btn btn-info btn-sm mr-2" @click="clearFilter(search_key)">
            {{ labelForKey(search_key) }}
            <span aria-hidden="true">&times;</span>
          </span>
          <span v-if="!empty" class="btn btn-secondary float-right" @click="clear">
            <i class="icon fe fe-trash"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import get from "lodash.get"
import has from "lodash.has"
import set from "lodash.set"
import _ from 'lodash'
import moment from 'moment'
import Url from 'url-parse'

function findLabelForControl(el) {
   var idVal = el.id;
   var labels = document.getElementsByTagName('label');
   for( var i = 0; i < labels.length; i++ ) {
      if (labels[i].htmlFor == idVal)
           return labels[i];
   }
}

function scoop(doc, el, search) {
  let type = el.getAttribute("type")
  let path = el.getAttribute("v-model")
  let metaPath = path.replace(/^search/,'meta')

  let label = findLabelForControl(el)

  if (label) {
    set(search, `${metaPath}.label`, label.textContent)
  }

  set(search, `${metaPath}.type`, type)
  if (el.tagName == "SELECT") {
    set(search, `${metaPath}.type`, 'select')
    let values = {}
    for (let j = 0; j < el.options.length; j++) {
      values[el.options[j].value] = el.options[j].text
    }
    set(search, `${metaPath}.options`, values)
  }

  if (el.tagName === "INPUT" && type === "radio") {
    if (el.checked) {
      set(search, path, el.value)
    }
    else if (!has(search, path)) {
      set(search, path, undefined)
    }
  }
  else if (el.tagName === "INPUT" && type === "checkbox") {
    let checkboxesWithSameName = doc.querySelectorAll(
      `input[type='checkbox'][v-model='${path}']`)
    if (checkboxesWithSameName.length > 1) {
      if (!has(search, path)) set(search, path, [])

      if (el.checked) {
        let values = get(search, path)
        values.push(el.value)
        set(search, path, values)
      }
    }
    else {
      set(search, path, el.checked)
    }
  }
  else if (el.tagName === "SELECT" && el.multiple) {
    let values = []
    for (let j = 0; j < el.selectedOptions.length; j++) {
      values.push(el.selectedOptions[j].value)
    }
    set(search, path, values)
    set(search, metaPath, el.options)
  }
  else if (el.tagName === "DATE-TIME-PICKER") {
    let date = null
    if (el.getAttribute(':value')) {
      date = moment(el.getAttribute(':value'), "YYYYMMDD")
    }
    else if (el.getAttribute('value')) {
      date = moment(el.getAttribute('value'), "YYYYMMDD")
    }

    if (date != null && date.isValid()){
      set(search, path, date.toDate())
    } else {
      set(search, path, null)
    }
  }
  else {
    set(search, path, el.value)
  }
}

export const getInitialData = function(doc) {
  const search = {}
  const inputs = doc.querySelectorAll("[v-model]")

  for (let i = 0; i < inputs.length; i++) {
    scoop(doc, inputs[i], search)
  }

  return search
}


export default {
  name: 'SearchForm',
  props:  {
    default_search: {
      type: String,
      required: true
    },
    global_search: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    var element = document.querySelector('#search_form')
    return getInitialData(element)
  },
  methods: {
    clearFilter(search_key) {
      this.$delete(this.search, search_key)
    },
    clear() {
      this.search = {}
    },
    labelForKey(search_key) {
      if(this.meta[search_key] && (this.meta[search_key].type == "select")) {
        return `${this.meta[search_key].label}: ${this.meta[search_key].options[this.search[search_key]]}`
      } else if(this.meta[search_key] && (this.meta[search_key].type == "checkbox")) {
        return `${this.meta[search_key].label}`
      } else {
        if (this.search[search_key] instanceof Date) {
          return `${this.meta[search_key].label}: ${moment(this.search[search_key]).format('DD/MM/YYYY')}`
        } else {
          return `${this.meta[search_key].label}: ${this.search[search_key]}`
        }
      }
    },
    emitSearch() {
      var params = _.mapValues(this.search, (searchValue) => {
        if (searchValue instanceof Date) {
          return moment(searchValue).format('YYYYMMDD')
        } else {
          return searchValue
        }
      })
      this.addFiltersToQueryString(params);
      this.$root.$emit('search-form-change', params)
    },
    addFiltersToQueryString(filters) {
      var currentUrl = new Url(window.location);
      var activeFilters = Object.entries(filters)
        .filter(([_key, value]) => value)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});

      currentUrl.set('query', activeFilters);
      window.history.replaceState({}, '', currentUrl);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.emitSearch()
    })
  },
  computed: {
    empty: function () {
      return _.isEmpty(_.compact(Object.values(this.search)))
    }
  },
  watch: {
    "search": {
      handler: function (after, before) {
        this.emitSearch()
      },
      deep: true,
    }
  }
}
</script>
