<template>
  <div :class="param_class">
    <vue-select :class="['w-100', frontend ? '' :  'form-control form-control-sm']" :options="options" :value='selected_option' :reduce='option => option.value' :filterable="!url" @search="onSearch" @input="onInput" :placeholder="$t('components.vue_select.placeholder')">
       <template v-slot:no-options>
         {{ $t('components.vue_select.no_matching_options') }}
       </template>
     </vue-select>
     <input :name="name" :id="id" type='hidden' :value="formatted_selected_value"/>
  </div>
</template>

<script>

import VueSelect from 'vue-select'

import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'searchable-select',
  components: {
    'vue-select': VueSelect,
  },
  props: {
    'url': String,
    'initial_options': {
      type: Array
    },
    'frontend': {
      type: Boolean,
      default: false
    },
    'value': {
      type: String,
      required: true
    },
    'name': {
      type: String,
      required: true
    },
    'id': {
      type: String,
      required: true
    },
    'param_class': {
      type: String,
      required: false
    }},
  data: function() {
    return {
      options: _.clone(this.initial_options || []),
      selected_value: _.clone(this.value),
    }
  },
  computed: {
    formatted_selected_value: function() {
      if (this.selected_value && (this.selected_value != 'null')) {
        console.log(this.selected_value)
        return this.selected_value
      } else {
        return ''
      }
    },
    selected_option: function() {
      if (!this.selected_value) {
        return null
      }

      let option = this.options.find( (option) => {
        return (option.value == this.selected_value)
      })

      return option
    }
  },
  created() {
    if (this.selected_value && this.url) {
      this.fetchOptions( null,this.selected_value, function() {}, this)
    }
  },
  methods: {
    onSearch: _.debounce(function (search, loading) {
      if (this.url && search && search.length) {
        this.fetchOptions(search, null, loading, this)
      }
    }, 300),
    fetchOptions: function( search, selected_value, loading, vm) {
      loading(true)
      axios.get(vm.url, { params:  { search: search, selected: selected_value }}).then((response) => {
        vm.options = response.data
        loading(false)
      })
    },
    onInput: function(selected_value) {
      this.selected_value = selected_value
      this.$emit('input', selected_value)
    },
  },
  watch: {
    value: function(newValue, oldValue) {
      if (newValue == null) {
        this.selected_value = null
        this.options = []
      }
    }
  }
}
</script>
