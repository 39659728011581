<template lang="pug">
  div
    full-calendar(:options="calendarOptions" )
</template>

<script>

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import nlCalendarLocale from '@fullcalendar/core/locales/nl';

export default {
  name: 'EventCalendar',
  components: {
    'full-calendar': FullCalendar,
  },
  props: {
    events_url: String,
    event_requests_url: String
  },
  methods: {
    updateFilters(payload) {
      this.filters = payload
    },
  },
  mounted() {
    this.$root.$on('search-form-change', _.debounce(this.updateFilters, 300))
  },
  computed: {
    eventSources: function() {
      let { event_source, ...extraParams} = this.filters
      let sources = []

      console.log(event_source)

      if (!event_source || (event_source == 'event')) {
        sources.push({
          url: this.events_url,
          extraParams: extraParams
        })
      }

      if (!event_source || (event_source == 'event_request')) {
        sources.push({
          url: this.event_requests_url,
          extraParams: extraParams
        })
      }

      return sources
    },
    calendarOptions: function() {
      return {
        plugins: [ dayGridPlugin, listPlugin, bootstrapPlugin],
        locales: [nlCalendarLocale],
        initialView: 'listMonth',
        themeSystem: 'bootstrap',
        eventContent: function({ event }) {
          return { html: `<i class="icon ${event.extendedProps && event.extendedProps.icon}"></i><span> ${event.title}</span>` }
        },
        headerToolbar: { left: "prev,next today", center: "title", right: "listMonth,listWeek,listDay" },
        footerToolbar: { left: "prev,next today", center: "", right: "listMonth,listWeek,listDay" },
        buttonText: {
          listMonth: 'Maand', listWeek: 'Week', listDay: 'Dag'
        },
        eventSources: this.eventSources
      }
    }
  },
  data() {
    return {
      filters: {},
    }
  },
}
</script>
