<template>
    <div class="row">
        <template v-if="show_in_modal">
            <div class="col-lg-6 d-flex align-items-center">
                <label >
                    {{uciIdToShow}}
                </label>
            </div>
            <div class="col-lg-6 text-right">
                <button type="button" class="btn btn-secondary" v-modal.modal-1 >{{ $t('components.uci_selector.request_uci_id_button') }}</button>
            </div>
        </template>

        <template v-if="show_in_modal">
            <modal ref="modal" hide-backdrop id="modal-1" size="lg" :title="$t('components.uci_selector.request_uci_id_title')" @shown="readDataRequestUCIId" @hidden="clear" @ok="updateUCIID">

                <uci-selector-content :person_id="person_id" :changed_uci_id="changed_uci_id" :uci_id="person_uci_id" :request_data_and_spin="request_data_and_spin" :force_token="force_token" :possible_uci_ids="possible_uci_ids"
                                      :request_and_spin="request_and_spin" :request_data="request_data" :request_new_and_spin="request_new_and_spin" :show_in_modal="show_in_modal"
                                      @personClicked="personClicked" @requestUCIId="requestUCIId" @requestNewUCIId="requestNewUCIId"></uci-selector-content>

                <template v-slot:modal-footer="{ ok, cancel }">
                    <template v-if="error != null">
                        <label class="text-danger">{{ error }}</label>
                    </template>
                    <b-button @click="ok()">
                        <template v-if="saving">
                            <b-spinner small label="Spinning"/>
                        </template>
                        OK
                    </b-button>
                    <b-button @click="cancel()">
                        Cancel
                    </b-button>
                </template>
            </modal>
        </template>
        <template v-else>
            <uci-selector-content :person_id="person_id" :changed_uci_id="changed_uci_id" :uci_id="person_uci_id" :request_data_and_spin="request_data_and_spin" :force_token="force_token" :possible_uci_ids="possible_uci_ids"
                                  :request_and_spin="request_and_spin" :request_data="request_data" :request_new_and_spin="request_new_and_spin" :show_in_modal="show_in_modal"
                                  @personClicked="personClicked" @requestUCIId="requestUCIId" @requestNewUCIId="requestNewUCIId"></uci-selector-content>

            <template v-if="person_uci_id == null">
                <template v-if="error != null">
                    <label class="text-danger">{{ error }}</label>
                </template>
                <b-button variant="outline-dark" class="ml-auto" @click="updateUCIID">
                    <template v-if="saving">
                        <b-spinner small label="Spinning"/>
                    </template>
                    {{ $t('components.uci_selector.update_uci_id') }}
                </b-button>
            </template>
            <template v-else>
                <b-button variant="outline-dark" class="ml-auto" @click="updateUCIInfo">{{ $t('components.uci_selector.update_uci_info') }}</b-button>
            </template>
        </template>
    </div>
</template>

<script>

import {BButton, BModal, BSpinner, VBModal} from 'bootstrap-vue'
import UCISelectorContent from "../UCISelectorContent/UCISelectorContent";
import axios from 'axios'
import Qs from 'qs' // Query String

    // Format nested params correctly for Rails
axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {

        return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false
        });
    };

    return config;
});

export default {
  name: 'UCISelector',
  props: {
    'uci_id': {
      type: String,
      default: null
    },
    'person_id': {
      type: Number,
      required: true
    },
    'read_data_url': {
      type: String,
      required: true
    },
    'request_url': {
      type: String,
      required: true
    },
    'request_new_url': {
      type: String,
      required: true
    },
    'update_uci_id_url': {
      type: String,
      required: true
    },
    'update_uci_info_url': {
      type: String
    },
    'success_url': {
      type: String
    },
    'show_in_modal': {
      type: Boolean,
      required: true
    }
  },
  components: {
    'modal': BModal,
    BSpinner,
    BButton,
    'uci-selector-content': UCISelectorContent
  },
  directives: {
    'modal': VBModal
  },
  data: function () {
    return {
      person_uci_id: this.uci_id,
      changed_uci_id: this.uci_id,
      possible_uci_ids: [],
      request_and_spin: false,
      request_data_and_spin: true,
      request_new_and_spin: false,
      saving: false,
      error: null,
      force_token: null,
      request_data: {}
    }
  },
  computed: {
    uciIdToShow: function () {
      if (this.person_uci_id != null) return this.person_uci_id
      return I18n.t('components.uci_selector.no_uci_id')
    },
    requestButtonText: function () {
      if (this.request_data_and_spin) {
        return I18n.t('components.uci_selector.wait_for_data')
      }
      else if (this.request_data.error == null) {
        return I18n.t('components.uci_selector.request_uci_id')
      }
      else {
        return I18n.t('components.uci_selector.fetch_ids_error', {error: this.request_data.error})
      }
    },
    requestNewUciIdAllowed: function () {
      return this.force_token != null
    }
  },
  methods: {
    readDataRequestUCIId() {
      let self = this
      self.request_data_and_spin = true

      let params = { "person_id": this.person_id }

      axios.get(this.read_data_url, { params: params }).then(function(response) {
        self.request_data = response.data
      })
      .catch(function(error) {
        self.request_data.error = error
      })
      .finally(function () {
        self.request_data_and_spin = false
      });
    },

    requestUCIId() {
      if (this.request_data_and_spin || this.request_data.error) return

      let self = this
      self.request_and_spin = true

      let params = { "person_id": this.person_id }

      axios.get(this.request_url, { params: params }).then(function(response) {
        self.force_token = response.data.force_token
        self.request_data.error = response.data.error
        self.possible_uci_ids = self.possible_uci_ids.concat(response.data.persons)
        response.data.persons.forEach(function(value, index) {
          if (value.match) {
            self.changed_uci_id = value.uciid
          }
        })
      })
      .catch(function(error) {
          self.request_data.error = error
      })
      .finally(function () {
        self.request_and_spin = false
      });
    },

    clear() {
      this.possible_uci_ids = [],
      this.force_token = null,
      this.error = null,
      this.request_data = {},
      this.changed_uci_id = this.person_uci_id
    },

    requestNewUCIId() {
      let self = this
      self.error = null
      self.request_new_and_spin = true

      let params = { "person_id": this.person_id, "force_token": this.force_token }

      axios.get(this.request_new_url, {params: params}).then(function(response) {
        self.force_token = null
        self.possible_uci_ids = self.possible_uci_ids.concat(response.data.persons)
        if (response.data.error_code === 0) {
          self.changed_uci_id = response.data.persons[0].uciid
        }
        else {
          self.error = response.data.error
        }
      })
      .catch(function(error) {
          self.error = error
      })
      .finally(function () {
        self.request_new_and_spin = false
      });
    },

    personClicked(value) {
        this.changed_uci_id = value
    },

    updateUCIID(modal_event) {
      let self = this
      self.error = null
      self.saving = true

      if (modal_event != null) {
        modal_event.preventDefault()
      }

      if (self.changed_uci_id == null)
      {
        self.saving = false
        return
      }

      let uci_id = self.changed_uci_id
      let params = { "person_id": self.person_id, "uci_id": uci_id }

      axios.get(this.update_uci_id_url, { params:  params }).then(function(response) {
        if (response.data.error_code !== 0) {
          self.error = response.data.error
        }
        else {
          self.person_uci_id = uci_id
          self.changed_uci_id = uci_id

          // Hide the modal manually if there is a modal
          if (modal_event != null) {
            self.$nextTick(() => {
              self.$refs.modal.hide()
            })
          }

          if (self.success_url != null) {
            window.location = self.success_url
          }
        }
      })
      .finally(function () {
        self.saving = false
      });
    },

    updateUCIInfo() {
      let self = this
      self.error = null
      self.saving = true

      let params = { "person_id": self.person_id, "uci_id": self.changed_uci_id }

      axios.get(this.update_uci_info_url, { params:  params }).then(function(response) {
        if (response.data.error_code === 0) {
          window.location = self.success_url
        }
        else {
          self.error = response.data.error
        }
      })
      .finally(function () {
        self.saving = false
      });
    },
  },

  mounted() {
      console.log(this.uci_id)
      if (!this.show_in_modal) {
          this.readDataRequestUCIId();
      }
  }
}
</script>
