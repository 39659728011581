<template>
<div class="float-right">
    <template v-if="order == 'asc'">
      <i class='fe fe-arrow-up'/>
    </template>

    <template v-else-if="order==='desc'">
      <i class='fe fe-arrow-down'/>
    </template>

    <template v-else>
    </template>
</div>
</template>

<script>
import findIndex from "lodash/findIndex";


export default {
    name: 'SortIcon',
    props: {
        sort: {
            type: Array,
            default: function () {
                return [];
            }
        },
        column: {
            type: Object,
            default: function () {
                return {};
            }
        },

    },
    data: function () {
        return {

        }
    },
    mounted() {

    },
    methods: {

    },
    computed: {
        order() {
            let index = findIndex(this.sort, {
                'vbt_col_id': this.column.vbt_col_id
            });
            if (index == -1) {
                return null;
            } else {
                return this.sort[index].order;
            }
        }
    },
}
</script>
