<template lang="pug">
  div
    .editor-holder
      input(type="hidden" :name="name" :value="JSON.stringify(value)")
</template>

<script>

import { JSONEditor } from '@json-editor/json-editor'
import _ from 'lodash'

export default {
  name: 'pre-registration-classifaction-rules-editor',
  props: ['race_categories', 'licence_categories', 'name', 'initial_value'],
  data: function() {
    return {
      editor: null,
      value: _.clone(this.initial_value)
    }
  },
  mounted() {
    let rules_schema = {
      type: 'object',
      title: 'Regels automatische wedstrijdindeling',
      properties: {
        rule_groups: {
          type: "array",
          title: "Indelingen",
          format: "tabs",
          items: { "$ref": "#/definitions/rule_group" }
        }
      },
      definitions: {
        rule: {
          type: "object",
          title: "Indelingsregel",
          format: "table",
          properties: {
            round: {
              title: "Benaming van de in te delen reeks (moet exact overeenkomen)",
              type: "string"
            },
            min_age: {
              title: "Minimum bereikte leeftijd in dat jaar",
              type: "integer",
              minimum: 0,
              maximum: 100,
            },
            max_age: {
              title: "Maximum bereikte leeftijd in dat jaar",
              type: "integer",
              minimum: 0,
              maximum: 100,
            },
            required_licence_categories: {
              type: "array",
              title: "Verplichte vergunningscategorieën",
              items: {
                type: "string",
                title: 'Renner heeft een van deze actieve vergunning(en)',
                enum: this.licence_categories.map( (licence_category) => { return licence_category.id.toString() }),
                options: {
                  enum_titles: this.licence_categories.map( (licence_category) => { return licence_category.abbreviation })
                }
              }
            },
            optional_licence_categories: {
              type: "array",
              title: "Extra vergunningscategorieën",
              items: {
                type: "string",
                title: 'Of renner heeft deze actieve vergunning(en) (onafhankelijk van leeftijd)',
                enum: this.licence_categories.map( (licence_category) => { return licence_category.id.toString() }),
                options: {
                  enum_titles: this.licence_categories.map( (licence_category) => { return licence_category.abbreviation })
                }
              }
            }
          }
        },
        rule_group: {
          type: "object",
          title: "Indeling",
          properties: {
            race_category_id: {
              title: "Wedstrijdcategorie",
              type: "string",
              enum: this.race_categories.map( (race_category) => { return race_category.id.toString() }),
              options: {
                enum_titles: this.race_categories.map( (race_category) => { return race_category.code })
              }
            },
            active_month_from: {
              title: "Actief vanaf de maand",
              type: "string",
              enum: ['1','2','3','4','5','6','7','8','9','10','11','12'],
              options: {
                enum_titles: ["januari", "februari", "maart", "april", "mei", "juni", "juli","augustus", "september", "oktober", "november", "december"]
              }
            },
            active_month_until: {
              title: "Actief tot en met de maand",
              type: "string",
              enum: ['1','2','3','4','5','6','7','8','9','10','11','12'],
              options: {
                enum_titles: ["januari", "februari", "maart", "april", "mei", "juni", "juli","augustus", "september", "oktober", "november", "december"]
              }
            },
            rules: {
              title: "Indelingsregels",
              type: "array",
              items: { "$ref": "#/definitions/rule" }
            }
          }
        }
      }

    }

    this.editor = new JSONEditor(this.$el.querySelector('.editor-holder'), {
      schema: rules_schema,
      theme: 'bootstrap4',
      disable_edit_json: true,
      remove_button_labels: true,
      no_additional_properties: true,
      required_by_default: true,
      disable_properties: true,
      iconlib: 'fontawesome5'
    })

    console.log("set value")
    console.log(this.value)
    this.editor.setValue(this.value)
    this.editor.on('change', (newValue) => {
      console.log("changed value")
      console.log(newValue)
      console.log(arguments)
      this.value = this.editor.getValue()
    })
  },
  watch: {
    value: function(value) {
      this.editor.setValue(this.value)
    },
  },
  destroyed() {
    this.editor.destroy();
  }
}
</script>
