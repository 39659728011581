<template lang="pug">
  .row(:class="[invalid ? `is-invalid ${this.in_valid_class}` : this.valid_class, 'no-gutters']")
    div(:class="[!date_only ? 'col-auto' : 'col', frontend ? 'frontend-date' : '' ]")
      vue-date-picker.w-100(:value='selected_date', :locale='{ id: $i18n.locale, masks: { L: "DD/MM/YYYY"}  }', :input-props='{ "name": name, "id": id, "size": "10", "maxlength": "10", class:[ "w-auto", frontend ? "" : "form-control",frontend ? "" : "form-control-sm", invalid ? "is-invalid" : ""] }', @input='onSelectDate', v-if='!time_only', :min-date='min_date', :max-date='max_date', :update-on-input="false")
    .col-5
      input.w-100(:class='["w-100","form-control","form-control-sm", invalid ? "is-invalid": "", "collapse", this.collapsed ? "hide": "show"]', :value='selected_time', v-if='!date_only', @change='onSelectTime', :minute-interval='5', v-mask="'##:##'" type="text" placeholder="uu:mm")
      input(:name='name', :id='id', type='hidden', :value='iso_value')
</template>

<script>

import VueSelect from 'vue-select'

import axios from 'axios'
import _ from 'lodash'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueTimepicker from 'vue2-timepicker'
import moment from 'moment'
import { nl } from 'vuejs-datepicker/dist/locale'
import { mask } from 'vue-the-mask'

export default {
  name: 'date-time-picker',
  components: {
    'vue-date-picker': DatePicker
  },
  directives: {
    'mask': mask
  },
  props: {
    'value': {
      type: String,
      default: ''
    },
    'frontend': {
      type: Boolean,
      default: false
    },
    'invalid': {
      type: Boolean,
      default: false
    },
    'collapsed': {
      type: Boolean,
      required: false
    },
    'name': {
      type: String,
      required: true
    },
    'id': {
      type: String,
      required: true
    },
    'min_date': {
      type: String ,
      required: false,
    },
    'max_date': {
      type: String,
      required: false,
    },
    'valid_class': {
      type: String ,
      required: false,
      default: ''
    },
    'invalid_class': {
      type: String ,
      required: false,
      default: ''
    },
    'date_only': {
      type: Boolean,
      required: false,
    },
    'time_only': {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    value: function() {
      this.parseValue()
    }
  },
  data: function() {
    return {
      selected_date: moment().toDate(),
      selected_time: '',
      languages: { nl: nl }
    }
  },
  computed: {
    'input_classes': function() {
    },
    'iso_value': function() {
      let serverFormats = []
      if (this.selected_date) {
        serverFormats.push(moment(this.selected_date).format("YYYY-MM-DD"))
      }

      if ((this.selected_time.length > 0) && !this.date_only) {
        serverFormats.push(`${this.selected_time}:00`)
      }

      return serverFormats.join('T')
    }
  },
  methods: {
    parseValue: function(){
      if (this.time_only) {
        this.selected_date = null

        let timeValid =  moment(_.clone(this.value),'HH:mm:ss',true).isValid()
        if(timeValid) {
          this.selected_time =  moment(_.clone(this.value), 'HH:mm:ss').format("HH:mm")
        } else {
          this.selected_time = ''
        }

      } else if(this.date_only) {

        this.selected_date =  this.value && moment(this.value).isValid() && _.clone(moment(this.value).toDate())
        this.selected_time = ''

      } else {
        this.selected_date =  this.value && moment(this.value).isValid() && _.clone(moment(this.value).toDate())
        let timeValid = moment(_.clone(this.value), moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,true).isValid()

        if (timeValid) {
          this.selected_time =  moment(_.clone(this.value), moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,true).format("HH:mm")
        } else {
          this.selected_time = ''
        }
      }
    },
    onSelectDate: function(selected_date) {

      if(selected_date) {
        console.log("emitting date")
        console.log(selected_date)
        this.selected_date = selected_date
        console.log(this.iso_value)
        this.$emit('input', this.iso_value)
      }
    },
    onSelectTime: function(event) {
      console.log("emitting time")
      if(this.date_only) {
        return
      } else {
        this.selected_time = event.target.value
        this.$emit('input', this.iso_value)
      }
    }
  },
  mounted() {
    this.parseValue()
  }
}
</script>

<style lang="scss">
@import 'stylesheets/components/DateTimePicker/style.scss';
.vdp-datepicker__calendar {
  z-index: 12000 !important;
}
</style>
