<template lang="pug">
  div
    template(v-if="paged")
      .clearfix
        .float-right
          | {{ valid_repeatable_entries.length ? page + 1 : 0 }}/{{ valid_repeatable_entries.length }}
    fieldset(v-for='(entry,index) in valid_repeatable_entries')
      template(v-if="!paged || index == page")
        div(v-if="!(entry.deleted == '1')")
          .row
            .col-md-11
              slot(:entry='entry', :index='index', :object_name='object_name')
            .col-md-1
              button.mt-2.btn.btn-secondary(@click='removeEntry($event,entry.id)')
                i.icon.fe.fe-trash
        div(v-if="(entry.deleted == '1' && entry.id) && !paged")
          input(type='hidden', :name="object_name + '[' + entry.id + '][id]'", :value='entry.id')
          input(type='hidden', :name="object_name + '[' + entry.id + '][deleted]'", value='1')
          .alert
            | {{ $t('components.repeatable_fields.removed') }} (
            a(href='', @click='reinstateEntry($event,entry.id)')
              | {{ $t('components.repeatable_fields.undo') }}
            |           )
    .alert.alert-info(v-if='valid_repeatable_entries.length === 0')
      | {{ $t('components.repeatable_fields.no_entries') }}
    hr.mt-2.mb-2(v-if='(!final_page || !first_page) && valid_repeatable_entries.length')
    template(v-if="paged")
      button.btn.btn-secondary(@click='previousPage' v-if='!first_page && valid_repeatable_entries.length')
        | {{ $t('components.repeatable_fields.previous') }}
      button.btn.btn-secondary(@click='nextPage' v-if='!final_page && valid_repeatable_entries.length')
        | {{ $t('components.repeatable_fields.next') }}
    hr.mt-2.mb-2
    .clearfix
      button.btn.btn-secondary(@click='addEntry')
        | {{ $t('components.repeatable_fields.add') }}
</template>

<script>

import _ from 'lodash'

export default {
  name: 'RepeatableFields',
  props: {
    value: {
    },
    default_entry: {
      type: Object
    },
    object_name: {
      type: String,
      required: true
    },
    emit: {
      type: String,
      required: false
    },
    paged: {
      type: Boolean,
      default: false
    },
    positioned: {
      type: Boolean,
      default: false
    },
    remove_unpersisted: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    let clonedEntries = [];


    if (this.value) {
      this.value.forEach(function(initial_entry, index)
      {
        let entry = _.cloneDeep(initial_entry);
        if (entry.id === "" || entry.id == null) {
          entry.id = 'new_' + Math.random().toString(36).substr(2, 9);
        }
        clonedEntries.push(entry);
      });
    }

    return {
      repeatableEntries: clonedEntries,
      page: 0
    }
  },
  computed: {
    final_page: function() {
      return (this.page == (this.valid_repeatable_entries.length - 1));
    },
    first_page: function() {
      return (this.page == 0);
    },
    valid_repeatable_entries: function() {
      if (!this.paged && !this.remove_unpersisted) return this.repeatableEntries

      let valid_entries = []
      this.repeatableEntries.forEach(function(entry){
        if (entry.deleted != '1') {
          valid_entries.push(entry)
        }
      })

      return valid_entries
    }
  },
  methods: {
    nextPage: function(event) {
      event.preventDefault();
      if(this.page + 1 < this.valid_repeatable_entries.length) {
        this.page = this.page + 1;
      }
    },
    previousPage: function(event) {
      if(this.page > 0) {
        this.page = this.page - 1;
      }
    },
    addEntry: function(event) {
      event.preventDefault();
      let newId = 'new_' + Math.random().toString(36).substr(2, 9);
      this.repeatableEntries.push(_.merge({ id: newId}, this.default_entry || {}));
      this.page = (this.valid_repeatable_entries.length - 1);
    },
    removeEntry: function(event, id) {
      event.preventDefault();
      let removedEntryIndex = this.repeatableEntries.findIndex(entry => {
        return (entry.id == id)
      })
      let removedEntry = this.repeatableEntries[removedEntryIndex]
      this.$set(this.repeatableEntries, removedEntryIndex, {...removedEntry, deleted: "1"})
      if (this.page >= this.valid_repeatable_entries.length) {
        this.page = this.valid_repeatable_entries.length - 1
      }
    },
    reinstateEntry: function(event, id) {
      event.preventDefault();
      let reinstatedEntryIndex = this.repeatableEntries.findIndex(entry => {
        return (entry.id == id)
      })
      let reinstatedEntry = this.repeatableEntries[reinstatedEntryIndex]
      this.$set(this.repeatableEntries, reinstatedEntryIndex, {...reinstatedEntry, deleted: "0"})
    },
    repeatableEntriesWithPosition: function() {
      return _.map(this.repeatableEntries, (repeatableEntry,index) => {
        return _.merge({}, repeatableEntry, { position: index + 1})
      })
    }
  },
  watch: {
    repeatableEntries: {
      deep: true,
      handler: function() {
        if (this.emit) {
          if (this.positioned) {
            this.$emit('change', this.emit, this.repeatableEntriesWithPosition())
          } else {
            this.$emit('change', this.emit, this.repeatableEntries)
          }
        }
      }
    }
  },
}
</script>
