<template lang="pug">
  .card
    .card-body
      .form-row
        licence-category-selector(:birthday='birthday', :gender="gender", :licence_category_search_path="extra_data.licence_category_search_path", :period="stepper_data.period", :club_id="licence_renewal ? club_id : null", :initial_value="extra_data.licence_category_id", @change='licenceCategoryChanged')
      template(v-if="(show_discipline_selector == 'true') && (licence_category_id != null) && (licence_category_id != '')")
        .form-row
          discipline-selector(:discipline_search_path="extra_data.discipline_search_path", :initial_value="extra_data.discipline_id", :licence_category_id="stepper_data.licence_category_id", :initial_secondary_disciplines="extra_data.secondary_disciplines", @changeDiscipline='disciplineChanged', @changeSecondaryDisciplines='secondaryDisciplinesChanged')
      validation-provider(rules='required', v-slot='{ errors }', v-if="(licence_category_id != null) && (licence_category_id != '') && !licence_renewal")
        .form-row.mt-4
          template(v-if="licence_category_individual")
            button-select#stepper_data_individual_licence(:frontend="!backend", v-model='individual_licence', :options="[{value: 'false', label: $t('components.club_and_licence_category_step.individual_licence_false') }, {value: 'true', label: $t('components.club_and_licence_category_step.individual_licence_true')}]", name='stepper_data_individual_licence')
          template(v-else)
            button-select#stepper_data_individual_licence(:frontend="!backend", v-model='individual_licence', :options="[{value: 'false', label: $t('components.club_and_licence_category_step.individual_licence_false') }]", name='stepper_data_individual_licence')
        .invalid-feedback {{ errors[0] }}
      .form-row.mt-2(v-if="licence_renewal && (!licence_category_individual || individual_licence == 'false')")
        .col-md-6
          label.form-control-label.required {{$t('components.club_and_licence_category_step.club')}}
        .col-md-6
          div {{ club_name }}
      .form-row.mt-2(v-if="(individual_licence == 'false') && (licence_category_id != null) && (licence_category_id != '') && !licence_renewal")
        validation-provider.fields.mt-2.string.required.stepper_data_club_id.col-md-6(rules='required', v-slot='{ errors }')
          .float-input__container.active.clearfix
            label.form-control-label.select.required(for='stepper_data_club_id') {{$t('components.club_and_licence_category_step.club_id')}}
            searchable-select#stepper_data_club_id(:url='club_search_path', :frontend='!backend', name='stepper_data_club_id', :value='club_id', v-model='club_id', :class="errors[0] ? 'is-invalid' : ''")
            .invalid-feedback {{ errors[0] }}
      template(v-if="licence_category_individual && (individual_licence == 'true') && (licence_category_id != null) && (licence_category_id != '') && (extra_data.province)")
        .form-row.mt-2
          .col-md-6
            div {{ $t('components.club_and_licence_category_step.province_club_subscription', {province: extra_data.province}) }}
        .form-row
          .col-md-6
            div {{ $t('components.club_and_licence_category_step.province_hint') }}
</template>

<script>
  import {BSpinner} from 'bootstrap-vue'
  import {ValidationProvider} from 'vee-validate'
  import SearchableSelect from "../SearchableSelect/SearchableSelect";
  import ButtonSelect from "../ButtonSelect/ButtonSelect";
  import Qs from "qs";
  import axios from "axios";
  import LicenceCategorySelector from "../LicenceCategorySelector/LicenceCategorySelector";
  import _ from "lodash";
  import DisciplineSelector from "../DisciplineSelector/DisciplineSelector";

  // Format nested params correctly for Rails
  axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {

      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false
      });
    };

    return config;
  });

  export default {
    name: "ClubAndLicenceCategoryStep",
    props: {
      stepper_data: {
        type: Object,
        required: true
      },
      extra_data: {
        type: Object,
        required: true
      },
      backend: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: function () {
      return {
        licence_category_id: null,
        licence_category_name: null,
        licence_category_individual: null,
        discipline_id: null,
        club_id: _.clone(this.extra_data.club_id),
        club_name: _.clone(this.extra_data.club_name),
        individual_licence: this.extra_data.individual_licence != null ? this.extra_data.individual_licence.toString() : null,
        show_discipline_selector: this.extra_data.show_discipline_selector != null ? this.extra_data.show_discipline_selector.toString() : null,
        searching_for_licence_categories: false,
        hidden_input: null,
        licence_renewal: _.clone(this.extra_data.licence_renewal)
      }
    },
    computed: {
      club_search_path: function () {
        let params = {
          "period": this.stepper_data.period,
          "licence_category_id": this.licence_category_id
        }

        let string_params = Qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })

        return `${this.extra_data.club_search_path}&${string_params}`;
      },
      birthday: function () {
        return this.extra_data.birthday || this.stepper_data.person_request_attributes.birthday
      },
      gender: function () {
        return this.extra_data.gender || this.stepper_data.person_request_attributes.gender
      }
    },
    components: {
      'validation-provider': ValidationProvider,
      'searchable-select': SearchableSelect,
      'button-select': ButtonSelect,
      BSpinner,
      'licence-category-selector': LicenceCategorySelector,
      'discipline-selector': DisciplineSelector
    },
    methods: {
      licenceCategoryChanged(value) {
        this.licence_category_id = value[0]
        this.licence_category_name = value[1]
        this.licence_category_individual = value[2]

        if (!this.licence_category_individual && this.individual_licence && !this.licence_renewal) {
          this.individual_licence = null;
        }
      },
      disciplineChanged(value) {
        this.discipline_id = value
      },
      secondaryDisciplinesChanged(value) {
        this.$emit('change', 'licence_secondary_discipline_requests_attributes', value)
      }
    },
    watch: {
      licence_category_id: function (newCategoryId, oldCategoryId) {
        // if this is a licence_renewal, club and individual_licence are fixed to that of the licence beeing renewed
        if (!this.licence_renewal) {
          this.club_id = null
          if(newCategoryId == null || newCategoryId === ''){
            this.individual_licence = null
          }
        }

        this.$emit('change', 'licence_category_id', newCategoryId)

        if (newCategoryId == null || newCategoryId === '') {
          this.discipline_id = null
        }
      },
      discipline_id: function (newDisciplineId, oldDisciplineId) {
        this.$emit('change', 'discipline_id', newDisciplineId)
      },
      licence_category_name: function (newCategoryName, oldCategoryName) {
        this.$emit('change', 'licence_category_name', newCategoryName)
      },
      club_id: function (newClubId, oldClubId) {
        this.$emit('change', 'club_id', newClubId)
      },
      individual_licence: function (newIndividualLicence, oldIndividualLicence) {
        if (this.individual_licence == 'true') {
          this.club_id = ''
        }
        this.$emit('change', 'club_id', this.club_id)
        this.$emit('change', 'individual_licence', newIndividualLicence)
      }
    }
  }
</script>
