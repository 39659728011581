<template lang="pug">
  .form-row(:class="param_class")
    div.form-group.col-md-4('v-for'= 'cascade in cascades')
      label.form-control-label {{ labels[cascade] }}
      select(:class="['w-100', frontend ? '' :  'form-control form-control-sm']" :value='selected_value' @input="onInput" :filterable="false" :placeholder="$t('components.remote_select.placeholder')" :disabled="disabled")
        option(:value='""')
        option(:value='option.value' v-for="option in options_for_cascade(cascade)" :selected="option.value === selected_value" :key="`${cascade}${option.value}`") {{ option[`${cascade}_label`] }}
    input(:name="name" :id="id" type='hidden' :value="selected_value")
</template>

<script>

import VueSelect from 'vue-select'

import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'cascading-select',
  components: {
  },
  props: {
    'options': {
      type: Array
    },
    'cascades': {
      type: Array
    },
    'labels': {
      type: Object
    },
    'frontend': {
      type: Boolean,
      default: false
    },
    'value': {
      type: String,
    },
    'name': {
      type: String,
      required: true
    },
    'id': {
      type: String,
      required: true
    },
    'param_class': {
      type: String,
      required: false
    },
    'disabled': {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      selected_value: _.clone(this.value),
    }
  },
  computed: {
    selected_cascades: function() {
      let selections = {}
      this.cascades.forEach( (cascade) => {
        selections[cascade] = this.selected_option && this.selected_option[`${cascade}_value`]
      })
      return selections
    },
    selected_option: function() {
      if (!this.selected_value) {
        return ''
      }

      let option = this.options.find( (option) => {
        return (option.value == this.selected_value)
      })

      return option
    },
  },
  methods: {
    options_for_cascade: function(cascade) {
      let filteredOptions = this.options.filter((option) => {
        let filteredCascades = _.takeWhile(this.cascades, (filteredCascade) => { return (filteredCascade != cascade) })
        return filteredCascades.every((filteredCascade) => {
          return (!this.selected_cascades[filteredCascade] ) || (option[`${filteredCascade}_value`] == this.selected_cascades[filteredCascade])
        })
      })

      let sortedOptions = _.sortBy(filteredOptions, (option) => {
        return [option[`${cascade}_label`], this.selected_value != option.value ]
      })

      return _.sortedUniqBy(sortedOptions, (option) => {
        return option[`${cascade}_value`]
      })
    },
    onInput: function(event) {
      if (event.target.value) {
        this.selected_value = event.target.value.toString()
      } else {
        this.selected_value = ''
      }
      console.log("input")
      console.log(event.target.value)
      this.$emit('input', this.selected_value)
    },
  }
}
</script>
