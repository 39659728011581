<template lang="pug">
  div.w-75
    template(v-if='searching_for_disciplines')
      // Hidden input has been put here so that validations can never pass when loading disciplines
      validation-provider.hidden(rules='required', v-slot='{ errors }')
        input#hidden_input(v-model="hidden_input", name="hidden_input", type="text")
      b-spinner.small(label="Spinning")
      | {{ $t('components.discipline_selector.searching_for_disciplines') }}
    template(v-else-if='disciplines.length == 0')
      // Hidden input has been put here so that validations can never pass when loading disciplines
      validation-provider.hidden(rules='required', v-slot='{ errors }')
        input#hidden_input2(v-model="hidden_input", name="hidden_input2", type="text")
      | {{ $t('components.discipline_selector.no_disciplines') }}
    template(v-else-if='disciplines.length == 1')
      .row
        .col-md-6
          label.form-control-label.required {{$t('components.discipline_selector.discipline_id')}}
        .col-md-6
          div {{ disciplines[0].name }}
    template(v-else)
      validation-provider.fields.mt-2.string.required.discipline_id(rules='required', v-slot='{ errors }')
        .float-input__container.active.clearfix
          label.form-control-label.select.required(for='discipline_id') {{$t('components.discipline_selector.discipline_id')}}
          div(:class="errors[0] ? 'select-dropdown is-invalid' : 'select-dropdown'")
            // Do NOT change to v-model, there is a bug on chrome ios and android browser for vue, those pickers don't fire change events
            select#discipline_id.select.required(name='discipline_id' :value='discipline_id' @input='evt=>discipline_id=evt.target.value')
              option(value='')
              template(v-for="discipline in disciplines")
                option(:value='discipline.id', :selected='discipline.id === discipline_id') {{discipline.name}}
          .invalid-feedback {{ errors[0] }}
      template(v-if="this.licence_secondary_discipline_requests.length")
        .mb-2 {{ $t('components.discipline_selector.secondary_disciplines') }}
        template(v-for="licence_secondary_discipline_request in this.licence_secondary_discipline_requests")
          label.custom-checkbox--container.d-block
            input.custom-checkbox(type="checkbox", v-model="licence_secondary_discipline_request.selected", :disabled="discipline_id == licence_secondary_discipline_request.discipline_id")
            span {{ licence_secondary_discipline_request.name }}
            span.custom-checkbox--box

</template>

<script>
import {BSpinner} from 'bootstrap-vue'
import {ValidationProvider} from 'vee-validate'
import axios from "axios";
import _ from "lodash";

export default {
  name: "DisciplineSelector",
  props: {
    licence_category_id: {
      type: Number,
      required: false
    },
    discipline_search_path: {
      type: String,
      required: true
    },
    initial_value: {
      type: Number,
      required: false
    },
    initial_secondary_disciplines: {
      type: Array,
      required: false,
      default: ()=> []
    }
  },
  data: function () {
    return {
      discipline_id: null,
      disciplines: [],
      searching_for_disciplines: false,
      hidden_input: null,
      process_initial_value: true,
      process_initial_secondary_disciplines: true
    }
  },
  computed: {
    licence_secondary_discipline_requests: function () {
      let result = []
      let self = this
      if (this.disciplines.length) {
        _.forEach(this.disciplines, function(value) {
          let discipline_request = {
            discipline_id: value.id,
            name: value.name,
            selected: (self.process_initial_secondary_disciplines && self.initial_secondary_disciplines) ? self.initial_secondary_disciplines.includes(value.id) : false
          }
          result.push(discipline_request)
        });

        this.process_initial_secondary_disciplines = false
      }

      return result
    }
  },
  components: {
    'validation-provider': ValidationProvider,
    BSpinner,
  },
  methods: {
    search_disciplines: function () {
      this.searching_for_disciplines = true

      let params = {"licence_category_id": this.licence_category_id}

      let self = this

      axios.get(this.discipline_search_path, {params: params}).then(function (response) {
        self.disciplines = response.data
        if(self.disciplines.length == 0){
          self.discipline_id = null
        }
        else if(self.disciplines.length == 1){
          self.discipline_id = self.disciplines[0]["id"]
        }
        else if(self.process_initial_value && self.initial_value != null &&
            self.disciplines.some(discipline => discipline.id == self.initial_value )) {
          self.discipline_id = self.initial_value
          self.process_initial_value = false
        }
        else {
          self.discipline_id = null
        }
      }).catch(function (error) {
        self.disciplines = []
        self.discipline_id = null
      }).finally(function () {
        self.searching_for_disciplines = false
      });
    }
  },
  watch: {
    licence_category_id: function (newVal, oldVal) {
      this.search_disciplines();
    },
    discipline_id: function (newDisciplineId, oldDisciplineId) {
      _.forEach(this.licence_secondary_discipline_requests, function(value) {
        if (value.discipline_id === newDisciplineId) {
          value.selected = false
        }
      });

      this.$emit('changeDiscipline', newDisciplineId)
    },
    licence_secondary_discipline_requests: {
      deep: true,
      handler: function(value) {
        this.$emit('changeSecondaryDisciplines', value)
      }
    }
  },
  mounted() {
    this.search_disciplines();
  }
}
</script>
