<template>
  <b-table
    v-bind="{...$props, ...$attrs}"
    :no-local-sorting="true"
    @sort-changed="sortingChanged"
    :sort-by="activeSortFieldKey"
    :sort-desc="activeSortDirectionDesc"
    :tbody-tr-class="rowClass"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
  </b-table>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import qs from 'qs';

export default {
  name: 'SortableBTable',
  components: {
    BTable
  },
  props: {
    fields: Array,
  },
  methods: {
    sortingChanged(ctx) {
      const [url, queryString] = window.location.toString().split('?');
      const qsObj = qs.parse(queryString);
      let direction = 'asc';

      if (this.activeSortField && this.activeSortField.sortKey === ctx.sortBy) {
        direction = this.activeSortDirectionDesc ? 'asc' : 'desc';
      }

      qsObj['sort'] = [{
        'field': ctx.sortBy,
        'direction': direction,
      }];

      window.location = [
        url,
        qs.stringify(qsObj, { arrayFormat: 'brackets' })
      ].join('?');
    },
    getFieldBySortKey(sortKey) {
      return this.fields.find(field => field.sortKey === sortKey);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;

      return item.highlight ? 'bg-orange-lighter' : '';
    }
  },
  computed: {
    activeSort: function () {
      const [, queryString] = window.location.toString().split('?');
      const qsObj = qs.parse(queryString);
      const sorts = qsObj['sort'] || [];

      return sorts[0];
    },
    activeSortField: function () {
      if (this.activeSort) {
        return this.getFieldBySortKey(this.activeSort['field']);
      }
    },
    activeSortFieldKey: function () {
      return this.activeSortField ? this.activeSortField.key : null;
    },
    activeSortDirectionDesc: function () {
      if (this.activeSort) {
        return this.activeSort['direction'] === 'desc';
      }
    },
  }
}
</script>
