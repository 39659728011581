var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.$t(this.help_text_key)))])]),_c('div',{staticClass:"form-row mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{staticClass:"fields string required",attrs:{"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"float-input__container active clearfix"},[_c('label',{staticClass:"form-control-label string required",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('components.account_step.email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"field sprout-field string required",class:errors[0] ? 'w-auto is-invalid' : 'w-auto',attrs:{"id":"email","type":"email","name":"email","size":"50"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"form-row mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{staticClass:"fields string required",attrs:{"rules":"required|confirmed:email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"float-input__container active clearfix"},[_c('label',{staticClass:"form-control-label string required",attrs:{"for":"email_confirmation"}},[_vm._v(_vm._s(_vm.$t('components.account_step.email_confirmation')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email_confirmation),expression:"email_confirmation"}],staticClass:"field sprout-field string required",class:errors[0] ? 'w-auto is-invalid' : 'w-auto',attrs:{"id":"email_confirmation","type":"email","name":"email_confirmation","size":"50","onpaste":"return false;"},domProps:{"value":(_vm.email_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email_confirmation=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),(_vm.searching_for_account)?[_c('validation-provider',{staticClass:"hidden",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hidden_input),expression:"hidden_input"}],attrs:{"id":"hidden_input","name":"hidden_input","type":"text"},domProps:{"value":(_vm.hidden_input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.hidden_input=$event.target.value}}})]}}],null,false,2741102802)}),(!_vm.account_search_error)?_c('b-spinner',{staticClass:"small",attrs:{"label":"Spinning"}}):_vm._e(),(_vm.account_search_error)?_c('label',[_vm._v(_vm._s(_vm.$t('components.account_step.account_search_error')))]):_vm._e()]:_vm._e(),(_vm.request_password && _vm.password_required && !_vm.searching_for_account)?[_c('div',{staticClass:"form-row mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{staticClass:"fields string required",attrs:{"rules":"required|password_complexity","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"float-input__container active clearfix"},[_c('label',{staticClass:"form-control-label string required",attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t('components.account_step.password')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"field sprout-field string required",class:errors[0] ? 'w-auto is-invalid' : 'w-auto',attrs:{"id":"password","type":"password","name":"password","size":"50","hint":_vm.$t('public.messages.password_complexity')},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,4192268037)})],1)]),_c('div',{staticClass:"form-row mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{staticClass:"fields string required",attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"float-input__container active clearfix"},[_c('label',{staticClass:"form-control-label string required",attrs:{"for":"password_confirmation"}},[_vm._v(_vm._s(_vm.$t('components.account_step.password_confirmation')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"field sprout-field string required",class:errors[0] ? 'w-auto is-invalid' : 'w-auto',attrs:{"id":"password_confirmation","type":"password","name":"password_confirmation","size":"50","onpaste":"return false;"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1655255148)})],1)])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }