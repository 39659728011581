import Vue from 'vue/dist/vue.esm'
import DataTable from './DataTable'
import SortableBTable from './SortableBTable'
import SidebarMenu from './SidebarMenu'
import RepeatableFields from './RepeatableFields'
import SearchForm from './SearchForm'
import Stepper from './Stepper'
import SearchableSelect from './SearchableSelect'
import RemoteSelect from './RemoteSelect'
import CascadingSelect from './CascadingSelect'
import DateTimePicker from './DateTimePicker'
import Event from './Event'
import EventCalendar from './EventCalendar'
import ButtonSelect from './ButtonSelect'
import ToggleHideableRows from './ToggleHideableRows'
import ToggleAllCheckboxes from './ToggleAllCheckboxes'

// Calendar
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import nlCalendarLocale from '@fullcalendar/core/locales/nl';

import VueI18n from 'vue-i18n'
import moment from 'moment'
import { BModal, VBModal } from 'bootstrap-vue'
import UCISelector from "./UCISelector";
import NationalNumberInput from "./NationalNumberInput";
import ClubAndLicenceCategoryStep from "./ClubAndLicenceCategoryStep/ClubAndLicenceCategoryStep";
import LicenceRequestPurchaseConfirmation from "./LicenceRequestPurchaseConfirmation/LicenceRequestPurchaseConfirmation";
import LicenceRequestPhysical from "./LicenceRequestPhysical/LicenceRequestPhysical";
import ClubLicenceRequestBatchRenewal from "./ClubLicenceRequestRenewal/ClubLicenceRequestBatchRenewal";
import CodaPaymentsReconciliator from "./CodaPaymentsReconciliator";
import ForeignRiderRequestLicenceCategory from "./ForeignRiderRequestLicenceCategory";
import PreRegistrationClassifier from "./PreRegistrationClassifier";
import PreRegistrationClassificationRulesEditor from "./PreRegistrationClassificationRulesEditor";
import AccountStep from "./AccountStep/AccountStep";
import PriceInformationStep from "./PriceInformationStep/PriceInformationStep";
import QualityStep from "./QualityStep/QualityStep";

import { BSpinner } from 'bootstrap-vue'
import { BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTable } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import VueNumeric from 'vue-numeric'

import PdfViewer from './PdfViewer'

import JsonEditor from 'vue-json-ui-editor'

import { mask } from 'vue-the-mask'

import './validations'

import VueClipboard from "vue-clipboard2";

moment.parseTwoDigitYear = function(yearString) {
  var year = parseInt(yearString)
  if (year > (moment().year() - 2000) ) {
    return year + 1900;
  } else {
    return year + 2000;
  }
}

Vue.use(VueI18n)
Vue.use(VueClipboard)
Vue.config.ignoredElements = [/^trix.*/, 'trix-editor', 'trix-toolbar']

let i18n = new VueI18n({
  locale: I18n.locale,
  messages: I18n.translations,
  dateTimeFormats: {
    'nl': {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      }
    },
  }
})

configure({
  // this will be used to generate messages.
  defaultMessage: (_, values) => i18n.t(`validations.${values._rule_}`, values)
});


document.addEventListener('DOMContentLoaded', () => {

  var vueInstances = []
  if(document.getElementsByTagName('trix-editor').length) {
    vueInstances = ['#sidebar', '#before-trix', '#after-trix']
  } else {
    vueInstances = ['#app']
  }

  vueInstances.forEach( (elementSelector) =>  {
    new Vue({
      el: elementSelector,
      i18n: i18n,
      components: {
        'modal': BModal,
        'data-table': DataTable,
        'sortable-b-table': SortableBTable,
        'sidebar-menu': SidebarMenu,
        'repeatable-fields': RepeatableFields,
        'searchable-select': SearchableSelect,
        'remote-select': RemoteSelect,
        'cascading-select': CascadingSelect,
        'button-select': ButtonSelect,
        'toggle-hideable-rows': ToggleHideableRows,
        'toggle-all-checkboxes': ToggleAllCheckboxes,
        'date-time-picker': DateTimePicker,
        'search-form': SearchForm,
        'stepper': Stepper,
        'EventCalendar': EventCalendar,
        'validation-provider': ValidationProvider,
        'validation-observer': ValidationObserver,
        'event': Event,
        'spinner': BSpinner,
        'uci-selector': UCISelector,
        'national-number-input': NationalNumberInput,
        'club-and-licence-category-step': ClubAndLicenceCategoryStep,
        'licence-request-purchase-confirmation': LicenceRequestPurchaseConfirmation,
        'licence-request-physical': LicenceRequestPhysical,
        'account-step': AccountStep,
        'price-information-step': PriceInformationStep,
        'quality-step': QualityStep,
        'full-calendar': FullCalendar,
        'club_licence_request_batch_renewal': ClubLicenceRequestBatchRenewal,
        'pdf-viewer': PdfViewer,
        'table-simple': BTableSimple,
        'b-thead': BThead,
        'b-th': BTh,
        'b-tr': BTr,
        'b-td': BTd,
        'b-tbody': BTbody,
        'b-table': BTable,
        'pre-registration-classifier': PreRegistrationClassifier,
        'pre-registration-classification-rules-editor': PreRegistrationClassificationRulesEditor,
        'vue-numeric': VueNumeric,
        'coda-payments-reconciliator': CodaPaymentsReconciliator,
        'json-editor': JsonEditor,
        'foreign-rider-request-licence-category': ForeignRiderRequestLicenceCategory,
      },
      directives: {
        'modal': VBModal,
        'mask' : mask,
      },
      methods: {
         moment: function () {
            return moment.apply(this,arguments);
        }
      },
      data() {
        return {
          calendarPlugins: [ dayGridPlugin, listPlugin, bootstrapPlugin],
          calendarLocales: [nlCalendarLocale],
        }
      },
      provide: {
        consoleLog(message) {
          console.log(message)
        }
      }
    })
  })

})

