var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.backend)?_c('validation-provider',{attrs:{"immediate":"","mode":"eager","rules":"national_number|existing_national_number:false","vid":"national_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('##.##.##-###.##'),expression:"'##.##.##-###.##'"}],class:['form-control form-control-sm', invalid ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.name},domProps:{"value":_vm.national_number},on:{"input":_vm.onInput}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,783277107)}):_c('validation-provider',{ref:"national_number",attrs:{"slim":"","immediate":"","mode":"eager","rules":"required|national_number|existing_national_number:true|strict_existing_national_number","vid":"national_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('div',{staticClass:"form-row mt-2"},[_c('div',{staticClass:"float-input__container active clearfix col-md-6"},[_c('label',{staticClass:"form_control-label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.$t('components.national_number_input.national_number')))]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('##.##.##-###.##'),expression:"'##.##.##-###.##'"},{name:"model",rawName:"v-model",value:(_vm.national_number),expression:"national_number"}],class:['field sprout-field', invalid ? 'is-invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.name,"type":"text"},domProps:{"value":(_vm.national_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.national_number=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]),(failedRules.existing_national_number || failedRules.strict_existing_national_number)?_c('div',{staticClass:"alert alert-danger col-md-12"},[(failedRules.existing_national_number)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.existingNationalNumberText)}}):_vm._e(),(failedRules.strict_existing_national_number)?_c('span',[_vm._v(_vm._s(_vm.$t('components.national_number_input.strict_existing_national_number_text')))]):_vm._e()]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }