<template lang="pug">
  .card
    .card-body
      .form-row
        label.form-control-label.required
          template(v-if="searching_price")
            | {{ '1 x ' + $t('components.licence_request_purchase_confirmation.licence') + ' ' + stepper_data.licence_category_name + ': ' }}
            spinner(small="true", label="Spinning")
          template(v-else)
            | {{ '1 x ' + $t('components.licence_request_purchase_confirmation.licence') + ' ' + stepper_data.licence_category_name + ': ' + this.price }}
      validation-provider(rules='required|must_be_true', v-slot='{ errors }')
        .form-row
          label.custom-checkbox--container
            input.custom-checkbox#stepper_data_sales_conditions_accepted(type="checkbox", name="stepper_data_sales_conditions_accepted", v-model="sales_conditions_accepted", :disabled="!price_found")
            span
              a(href="https://cycling.vlaanderen/algemene-voorwaarden" target="_blank")
                span.underlined
                  | {{ $t('components.licence_request_purchase_confirmation.confirm_purchase') }}
            span.custom-checkbox--box
        .invalid-feedback {{ errors[0] }}
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import ButtonSelect from "../ButtonSelect/ButtonSelect";
    import axios from 'axios'
    import { BSpinner } from 'bootstrap-vue'
    import moment from "moment";

    export default {
      name: "LicenceRequestPurchaseConfirmation",
      props: {
        stepper_data: {
          type: Object,
          required: true
        },
        extra_data: {
          type: Object,
          required: true
        }
      },
      data: function () {
        var sales_conditions_accepted = _.clone(this.stepper_data.sales_conditions_accepted)

        return {
          sales_conditions_accepted: sales_conditions_accepted,
          price: "",
          price_found: false,
          searching_price: true,
          initialized: false
        }
      },
      components: {
        'validation-provider': ValidationProvider,
        'button-select': ButtonSelect,
        'spinner': BSpinner
      },
      methods: {
        search_price: function (licenceCategoryId) {
          this.sales_conditions_accepted = false
          this.searching_price = true
          this.price_found = false
          axios.get(this.extra_data.price_url,
            {
              params: {
                price_category: (this.extra_data.price_category || "licence"),
                licence_category_id: this.stepper_data.licence_category_id,
                individual: this.extra_data.individual_price_possible && this.stepper_data.individual_licence,
                period: this.stepper_data.period,
                licence_request_type: this.stepper_data.licence_request_type,
                upgrade_licence_id: this.stepper_data.upgrade_licence_id,
                generate_physical: this.stepper_data.generate_physical ? 1 : 0
              }
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }).then((response) => {
            this.price = response.data.price

            if (response.data.error) {
              this.price_found = false
            } else {
              this.price_found = true
            }

            this.searching_price = false
          }).catch(() => {
          })
        }
      },
      watch: {
        sales_conditions_accepted: function (newAccepted, oldAccepted) {
          this.$emit('change', 'sales_conditions_accepted', newAccepted)
        },
        'stepper_data.period': {
          immediate: true,
          handler(newPeriod, oldPeriod) {
            if (this.initialized)
            {
              console.log("period")
              this.search_price()
            }
          }
        },
        'stepper_data.licence_category_id': {
          immediate: true,
          handler(newCategoryId, oldCategoryId) {
            if (this.initialized)
            {
              console.log("licence_category_id")
              this.search_price()
            }
          }
        },
        'stepper_data.individual_licence': {
          immediate: true,
          handler() {
            if (this.initialized)
            {
              this.search_price()
            }
          }
        },
        'stepper_data.generate_physical': {
          immediate: true,
          handler() {
            if (this.initialized)
            {
              this.search_price()
            }
          }
        }
      },
      mounted: function () {
        console.log("mounted")
        this.search_price();
        this.initialized = true
      }
    }
</script>
